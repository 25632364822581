import * as Sentry from '@sentry/vue'

/**
 * @typedef Violation
 * @type {object}
 * @property {string} property_path
 * @property {string} message
 *
 * Set the errors for each violation provided.
 * This function expects to be called within a component.
 * @function handlePropertyPathViolations
 * @param {Array<Violation>} violations
 * @param {any} [refs]
 *
 * @example
 * handlePropertyPathViolations.call(this, response.data.error.violations)
 */
function handlePropertyPathViolations (violations, refs) {
  let _refs = refs
  if (!_refs) _refs = this.$refs

  // @ts-ignore
  violations.forEach((violation) => {
    const providerName = `${violation.property_path}-provider`
    const provider = _refs[providerName]

    if (!provider) {
      const err = new Error(`The provider "${providerName}" does not exists.`)
      Sentry.captureException(err, {
        extra: {
          violation_property_path: violation.property_path,
          violation_message: violation.message
        }
      })
      throw err
    }

    provider.setErrors([
      violation.message
    ])
  })
}

export default handlePropertyPathViolations
