<template>
  <div
    class="ctk-nav-bar-user-menu tw-relative"
    v-click-outside="blur"
  >
    <button
      v-if="getUserInfos"
      :title="getUserFullName"
      :class="{
        active: isUserCurrentRoute
      }"
      class="ctk-nav-bar-user-menu__infos tw-appearance-none tw-border-none tw-cursor-pointer tw-flex tw-items-center tw-pl-6 tw-truncate"
      @click="toggle"
    >
      <div class="tw-flex tw-items-center tw-truncate">
        <ctk-avatar
          :user="getUserInfos"
          class="tw-block lg:tw-hidden tw-flex-grow-0 tw-flex-shrink-0"
          white
        />
        <div class="ctk-nav-bar-user-menu__infos__names tw-hidden lg:tw-block tw-text-left tw-truncate">
          <div
            class="ctk-nav-bar-user-menu__infos__names__fullname tw-truncate tw-text-sm"
            v-text="getUserFullName"
          />
          <div
            class="ctk-nav-bar-user-menu__infos__names__company tw-truncate tw-text-xs"
            v-text="getUserInfos.companies[0].name"
          />
        </div>
        <ui-material-icon
          :class="{
            open: visible
          }"
          class="ctk-nav-bar-user-menu__infos__icon tw-ml-2 tw-my-0"
          name="arrow_drop_down"
        />
      </div>
    </button>
    <transition
      name="fade"
    >
      <navbar-user-navigation
        v-if="visible"
        class="tw-absolute tw-right-0 tw-z-10"
        aria-live="polite"
        @close="blur"
      />
    </transition>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { directive } from 'v-click-outside'

  import NavbarUserNavigation from './_subs/NavbarUserNavigation/index.vue'
  import CtkAvatar from '@/components/CtkAvatar/index.vue'

  /**
   * User informations within the header, with it's dropdown menu
   * @module component - CtkNavBarUserMenu
   */
  export default {
    name: 'CtkNavBarUserMenu',
    components: {
      NavbarUserNavigation,
      CtkAvatar
    },
    directives: {
      clickOutside: directive
    },
    data () {
      return {
        visible: false,
        showItems: false
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getUserInfos',
        'getUserFullName'
      ]),
      /**
       * Returns true if the current route belongs from
       * one of the items in the user menu.
       * @function isUserCurrentRoute
       * @returns {boolean}
       */
      isUserCurrentRoute () {
        const routes = [
          'AccountProfile',
          'AccountPassword',
          'AccountCompany',
          'AccountLocale'
        ]

        return routes.includes(this.$route.name)
      }
    },
    methods: {
      blur () {
        this.visible = false
      },
      toggle () {
        this.visible = !this.visible
      }
    }
  }
</script>

<style lang="scss" scoped>

  .ctk-nav-bar-user-menu {
    &,
    &__infos {
      height: 55px;
    }

    &__infos {
      background: transparent;
      color: rgba(white, 0.8);
      transition: background-color 200ms, color 200ms;
      max-width: 250px;

      &:hover {
        background-color: $primary-lighten;
      }

      &:focus {
        background-color: $primary-lighten;
        color: white;
        outline: none;
      }

      &.active {
        background-color: $primary;
        color: white;
        outline: none;
        border-bottom: 3px solid $brand-accent;
      }

      &__names {
        line-height: 1.1rem;
      }

      &__icon {
        transition: transform 200ms;
        transform: scale(0.7);

        &.open {
          transform: rotate(180deg) scale(0.7);
        }
      }
    }

    .navbar-user-navigation {
      top: 55px;
    }
  }

</style>
