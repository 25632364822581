var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"offer-card tw-relative tw-bg-white focus:tw-outline-none focus-visible:tw-outline-solid-blue tw-border-none tw-p-0 tw-text-sm tw-w-full tw-text-gray-900 hover:tw-text-gray-900 tw-transition-colors tw-transition-shadow",class:{
    'offer-card--viewed': _vm.offer.is_viewed,
    'has-chips': _vm.hasChips,
    'enable-hover': !_vm.disableHover,
    'bg-f2': _vm.proposition,
    'offer-card--active': !_vm.proposition && _vm.getCurrentOffer && _vm.getCurrentOffer.uuid === _vm.offer.uuid
  }},[_vm._t("chips"),_c('div',{staticClass:"offer-card__content tw-relative tw-flex tw-px-5 tw-pt-6 tw-pb-2 tw-truncate"},[_c('div',{staticClass:"tw-flex-1 tw-truncate"},_vm._l((['pickup', 'delivery']),function(direction,k){return _c('div',{key:k,staticClass:"offer-card__content__line tw-truncate",class:{
          'tw-pb-3': direction === 'pickup'
        }},[_c('ctk-address',{staticClass:"tw-flex tw-flex-1 tw-truncate tw--mb-1",attrs:{"address":_vm.offer[direction].address,"has-street-name":false}}),_c('ctk-timeslot-date',{staticClass:"tw-text-gray-700 tw-ml-8",attrs:{"flexible":_vm.offer[direction].time_slot.flexible,"direction":direction}},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.offer[direction].time_slot.date,'LL'))+" ")])],1)}),0),_c('ctk-subsidiary',{staticClass:"tw-mr-2",attrs:{"subsidiary":_vm.offer.bought_by}}),_c('price-chip',{staticClass:"tw-text-base tw-mb-auto tw-flex-shrink-0"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.offer.pricing.carrier_price || _vm.offer.pricing.price),_vm.offer.pricing.currency, _vm.$i18n.locale, true))+" ")])],1),_c('div',{staticClass:"offer-card-proposal-container"},[(_vm.haveProposalTriangle && _vm.offer.proposal)?_c('proposal-triangle',{attrs:{"proposal":_vm.offer.proposal,"currency":_vm.offer.pricing.currency,"small":""}}):_vm._e(),(_vm.offer.proposal || _vm.offer.proposal_count > 0)?_c('offer-item-proposals',{staticClass:"tw-ml-6",class:{
        active: !_vm.proposition && _vm.getCurrentOffer && _vm.getCurrentOffer.uuid === _vm.offer.uuid
      },attrs:{"offer":_vm.offer,"has-best-proposals":_vm.offer.proposal_count > 0,"card":""}}):_vm._e()],1),_c('div',{staticClass:"offer-card__footer tw-flex tw-items-center tw-px-4 tw-mb-6"},[(_vm.offer.load.type !== 'pallets')?_c('div',{staticClass:"offer-card__footer__section tw-flex tw-items-center tw-justify-center tw-mr-8"},[_c('ui-ctk-icon',{staticClass:"tw-text-blue-600 tw--ml-1",attrs:{"name":"size"}}),_c('div',{staticClass:"offer-card__footer__section__value tw-text-secondary",domProps:{"textContent":_vm._s(_vm.$t('lm', {
          lm: _vm.$n(_vm.offer.load.linear_meters)
        }))}})],1):_vm._e(),(_vm.offer.load.type === 'pallets')?_c('div',{staticClass:"offer-card__footer__section tw-flex tw-items-center tw-justify-center tw-mr-8"},[_c('ui-ctk-icon',{staticClass:"tw-text-blue-600 tw--ml-1",attrs:{"name":"pallets"}}),_c('div',{staticClass:"offer-card__footer__section__value tw-text-secondary",domProps:{"textContent":_vm._s(((_vm.$t('pallet_unit', {
          count: _vm.$n(_vm.offer.load.quantity)
        })) + " " + (_vm.offer.load.format)))}})],1):_vm._e(),_c('div',{staticClass:"offer-card__footer__section tw-flex tw-items-center tw-justify-center tw-mr-8"},[_c('ui-ctk-icon',{staticClass:"tw-text-blue-600 tw--ml-2",attrs:{"name":"weight"}}),_c('div',{staticClass:"offer-card__footer__section__value tw-text-secondary",domProps:{"textContent":_vm._s(_vm.$t('weight_unit', {
          weight: _vm.$n(_vm.offer.load.weight)
        }))}})],1),(_vm.isTailLiftRequired || _vm.isHandledByDriver || _vm.hasAccessibility || _vm.hasRdv)?_c('div',{staticClass:"tw-flex tw-items-center tw-justify-center tw-text-blue-600 tw--ml-2"},[(_vm.isTailLiftRequired)?_c('ui-ctk-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":_vm.tailLiftTooltip,"name":"hatch"}}):_vm._e(),(_vm.isHandledByDriver)?_c('ui-ctk-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":_vm.driverHandlingTooltip,"name":"logistic"}}):_vm._e(),(_vm.hasRdv)?_c('ui-ctk-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":_vm.rdvTooltip,"name":"scheduled"}}):_vm._e(),(_vm.hasAccessibility)?_c('ui-ctk-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"tw-text-yellow-500",attrs:{"title":_vm.accessibilityTooltip,"name":"warning-full"}}):_vm._e()],1):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }