<script>
  import { defineComponent } from '@vue/composition-api'

  const ALLOWED_SIZES = ['sm', 'md']
  const ALLOWED_COLORS = ['green', 'blue', 'orange', 'cyan', 'purple', 'teal', 'red', 'pink']

  /**
   * @module component - UIBadge
   */
  export default defineComponent({
    name: 'UiBadge',
    props: {
      color: {
        type: String,
        default: 'green',
        validator: (/** @type {string} */ value) => ALLOWED_COLORS.includes(value)
      },
      size: {
        type: String,
        default: 'md',
        validator: (/** @type {string} */ value) => ALLOWED_SIZES.includes(value)
      },
      neutral: {
        type: Boolean,
        default: false
      },
      bordered: {
        type: Boolean,
        default: false
      }
    },
    render (createElement) {
      const _this = this

      /**
       * @function hasSlot
       * @param {string} slotName
       * @returns {boolean}
       */
      function hasSlot (slotName) {
        // @ts-ignore
        return _this.$slots[slotName] && !!_this.$slots[slotName]
      }

      const hasContent = hasSlot('default')
      const hasLeftIcon = hasSlot('left-icon')

      const classes = []

      if (this.size) classes.push(`ui-badge--${this.size}`)
      if (this.neutral) classes.push('ui-badge--neutral')
      if (this.color) classes.push(`ui-badge--${this.color}`)
      if (this.bordered) classes.push(`ui-badge--bordered--${this.color}`)
      if (hasLeftIcon) classes.push('ui-badge--left-icon')
      if (hasContent) classes.push('ui-badge--content')

      const content = createElement('div', {
        staticClass: 'ui-badge__content'
      }, [
        ...(this.$slots.default ? this.$slots.default : [])
      ])

      return createElement('div', {
        class: classes,
        staticClass: 'ui-badge',
        attrs: {
          ...this.$attrs
        }
      }, [
        hasLeftIcon && this.$slots['left-icon'],
        content
      ])
    }
  })
</script>

<style lang="scss" scoped>
.ui-badge {
  border-radius: 9999px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  font-weight: 500;
  line-height: 1.5;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ui-badge__content {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ui-badge--md {
  font-size: 0.875rem;
  height: 1.375rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.ui-badge--sm {
  font-size: 0.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 1.125rem;
}
.ui-badge--neutral {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 241, 243, var(--tw-bg-opacity));
}
.ui-badge--neutral.ui-badge--green {
  --tw-text-opacity: 1;
  color: rgba(61, 170, 107, var(--tw-text-opacity));
}
.ui-badge--neutral.ui-badge--blue {
  --tw-text-opacity: 1;
  color: rgba(29, 66, 137, var(--tw-text-opacity));
}
.ui-badge--neutral.ui-badge--orange {
  --tw-text-opacity: 1;
  color: rgba(255, 168, 0, var(--tw-text-opacity));
}
.ui-badge--neutral.ui-badge--cyan {
  --tw-text-opacity: 1;
  color: rgba(2, 29, 73, var(--tw-text-opacity));
}
.ui-badge--neutral.ui-badge--teal {
  --tw-text-opacity: 1;
  color: rgba(50, 139, 88, var(--tw-text-opacity));
}
.ui-badge--neutral.ui-badge--purple {
  --tw-text-opacity: 1;
  color: rgba(132, 67, 185, var(--tw-text-opacity));
}
.ui-badge--neutral.ui-badge--red {
  --tw-text-opacity: 1;
  color: rgba(181, 31, 23, var(--tw-text-opacity));
}
.ui-badge--neutral.ui-badge--pink {
  --tw-text-opacity: 1;
  color: rgba(248, 199, 196, var(--tw-text-opacity));
}
.ui-badge:not(.ui-badge--neutral):not(.ui-badge--bordered).ui-badge {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.ui-badge:not(.ui-badge--neutral):not(.ui-badge--bordered).ui-badge--green {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 245, 219, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(50, 139, 88, var(--tw-text-opacity));
}
.ui-badge:not(.ui-badge--neutral):not(.ui-badge--bordered).ui-badge--blue {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 228, 249, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(29, 66, 137, var(--tw-text-opacity));
}
.ui-badge:not(.ui-badge--neutral):not(.ui-badge--bordered).ui-badge--orange {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 168, 0, var(--tw-bg-opacity));
}
.ui-badge:not(.ui-badge--neutral):not(.ui-badge--bordered).ui-badge--cyan {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 228, 249, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(29, 66, 137, var(--tw-text-opacity));
}
.ui-badge:not(.ui-badge--neutral):not(.ui-badge--bordered).ui-badge--teal {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 245, 219, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(50, 139, 88, var(--tw-text-opacity));
}
.ui-badge:not(.ui-badge--neutral):not(.ui-badge--bordered).ui-badge--purple {
  --tw-bg-opacity: 1;
  background-color: rgba(132, 67, 185, var(--tw-bg-opacity));
}
.ui-badge:not(.ui-badge--neutral):not(.ui-badge--bordered).ui-badge--red {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 199, 196, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(181, 31, 23, var(--tw-text-opacity));
}
.ui-badge:not(.ui-badge--neutral):not(.ui-badge--bordered).ui-badge--pink {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 199, 196, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(39, 39, 42, var(--tw-text-opacity));
}
.ui-badge--bordered {
  --tw-border-opacity: 1;
  border-color: rgba(39, 84, 145, var(--tw-border-opacity));
  border-style: solid;
  border-width: 1px;
  border-width: 2px;
}
.ui-badge--bordered--green {
  --tw-border-opacity: 1;
  border-color: rgba(61, 170, 107, var(--tw-border-opacity));
}
.ui-badge--bordered--blue {
  --tw-border-opacity: 1;
  border-color: rgba(39, 84, 145, var(--tw-border-opacity));
}
.ui-badge--bordered--orange {
  --tw-border-opacity: 1;
  border-color: rgba(255, 168, 0, var(--tw-border-opacity));
}
.ui-badge--bordered--cyan {
  --tw-border-opacity: 1;
  border-color: rgba(2, 29, 73, var(--tw-border-opacity));
}
.ui-badge--bordered--teal {
  --tw-border-opacity: 1;
  border-color: rgba(50, 139, 88, var(--tw-border-opacity));
}
.ui-badge--bordered--purple {
  --tw-border-opacity: 1;
  border-color: rgba(132, 67, 185, var(--tw-border-opacity));
}
.ui-badge--bordered--red {
  --tw-border-opacity: 1;
  border-color: rgba(150, 25, 19, var(--tw-border-opacity));
}
.ui-badge--bordered--pink {
  --tw-border-opacity: 1;
  border-color: rgba(248, 199, 196, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(39, 39, 42, var(--tw-text-opacity));
}
.ui-badge .ctk-font::before {
  position: relative;
}
.ui-badge.ui-badge--sm .ctk-font, .ui-badge.ui-badge--sm .material-icons {
  display: none;
}
.ui-badge.ui-badge--md .ctk-font {
  width: 12px;
  height: 12px;
}
.ui-badge.ui-badge--md .material-icons {
  font-size: 12px;
}
.ui-badge.ui-badge--md .ctk-font::before {
  top: -12px;
  margin-left: -6px;
  font-size: 24px;
}
.ui-badge--left-icon.ui-badge--content .ui-icon:first-child {
  margin-right: 0.5rem;
}
</style>
