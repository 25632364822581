<template>
  <svg
    :width="`${size}px`"
    :height="`${size}px`"
    xmlns="http://www.w3.org/2000/svg"
    class="ui-loader-ceva"
    viewBox="0 0 401 258"
  >
    <defs />
    <path d="M400.037 257.958L277.445 61.1826L276.948 60.3944L259.535 32.4444C258.291 30.4174 256.889 28.458 255.374 26.5887C242.981 10.3953 223.962 0.958496 203.316 0.958496C203.112 0.958496 202.909 0.958496 202.728 0.958496C202.027 0.958496 199.177 0.958496 198.476 0.958496C198.273 0.958496 198.069 0.958496 197.888 0.958496C177.241 0.958496 158.223 10.3953 145.83 26.5887C144.293 28.458 142.891 30.3949 141.669 32.4444L124.256 60.3944L0.0366211 257.958H243.184L180.453 5.59805L338.277 257.981H400.037V257.958Z" />
  </svg>
</template>

<script>
/**
 * @module component - UiLoaderCeva
 * @param {number} [size=40]
 * @param {boolean} [white=false]
 */
  export default {
    name: 'UiLoaderCeva',
    props: {
      size: {
        type: [Number, String],
        default: 40
      },
      white: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>

.ui-loader-ceva {
  animation: rotate 2s ease-in infinite;

  path {
    fill: #E30613;
  }

  @keyframes rotate {
    0% { transform: rotateY(0deg); }
    100% { transform: rotateY(360deg); }
  }
}

</style>
