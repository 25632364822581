<template>
  <div class="ctk-input-text">
    <div
      ref="parent"
      :class="{
        'is-disabled': isDisabled,
        'is-focused': isFocus || valid,
        'has-value': value,
        'has-error': error,
        'has-hint': hint && !value,
        'has-prefix': hasPrefix
      }"
      :data-id="id"
      class="ctk-input-text__field tw-font-normal tw-flex tw-relative tw-appearance-none tw-bg-white tw-w-full tw-px-3 tw-py-0"
      @click="focusInput"
    >
      <div
        v-if="hasPrefix"
        data-test="prefix-icon"
        class="ctk-input-text__field__prefix tw-absolute tw-left-0 tw-top-0 tw-bottom-0 tw-my-auto tw-mx-0 tw-flex tw-items-center flex-fixed"
      >
        <slot name="prefix" />
      </div>
      <div class="tw-flex-1 tw-h-full">
        <label
          :class="{
            'ctk-input-text__field__label--invalid' : error,
            'has-clear': clearable
          }"
          :for="id"
          :title="hintValue || labelValue"
          class="ctk-input-text__field__label tw-truncate tw-absolute"
        >
          {{ hintValue || labelValue }}
        </label>
        <input
          ref="CtkInputText"
          :id="id"
          v-bind="$attrs"
          v-model="inputValue"
          :type="fieldType"
          :placeholder="labelValue"
          :required="isRequired"
          :class="{
            'has-clear': clearable
          }"
          class="ctk-input-text__field__input tw-appearance-none tw-border-none tw-h-full tw-w-full tw-p-0"
          @focus="onFocus"
          @blur="onBlur"
          @keyup="onKeyUp"
        >

        <transition name="fade">
          <button
            v-if="hasPasswordToggle"
            :title="$t(fieldType === 'text'
              ? 'app.buttons.hide_password'
              : 'app.buttons.view_password')"
            tabindex="-1"
            class="ctk-input-text__field__toggle-password"
            type="button"
            @click="togglePassword"
          >
            <span class="ctk-input-text__field__toggle-password__effect" />
            <span>
              <ui-ctk-icon
                :name="fieldType === 'text' ? 'hide' : 'show'"
                data-test="icon"
              />
            </span>
          </button>
        </transition>

        <transition name="fade">
          <button
            v-if="clearable && inputValue && !isDisabled"
            :title="$t('app.buttons.reset-input')"
            tabindex="-1"
            class="ctk-input-text__field__clear"
            type="button"
            @click="clear"
          >
            <span class="ctk-input-text__field__clear__effect" />
            <ui-ctk-icon
              name="close"
              class="ctk-input-text__field__clear__icon"
              data-test="clear-icon"
            />
          </button>
        </transition>
      </div>

      <div
        v-if="suffix"
        class="ctk-input-text__field__suffix tw-text-xs tw-text-gray-600"
      >
        {{ suffix }}
      </div>

      <div
        v-if="loader"
        class="loader tw-absolute tw-bottom-0 tw-w-full tw-overflow-hidden"
      />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  /**
   * @module component - ctkInputText
   * @emits inpu
   * @emits clear
   */
  export default defineComponent({
    name: 'CtkInputText',
    inheritAttrs: false,
    props: {
      id: {
        type: String,
        default: 'CtkInputText'
      },
      label: {
        type: String,
        default: 'Label'
      },
      value: {
        type: [String, Number, Object],
        default: null
      },
      hint: {
        type: [String, Boolean],
        default: null
      },
      error: {
        type: Boolean,
        default: false
      },
      valid: {
        type: Boolean,
        default: false
      },
      color: {
        type: String,
        default: '#287696'
      },
      validColor: {
        type: String,
        default: '#96bf31'
      },
      loader: {
        type: Boolean,
        default: false
      },
      clearable: {
        type: Boolean,
        default: false
      },
      hasPrefix: {
        type: Boolean,
        default: false
      },
      suffix: {
        type: String,
        default: null
      },
      passwordToggle: {
        type: Boolean,
        default: false
      },
      ignoreOptional: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const { state: inputValue } = useModelGetterSetter(props, 'value')

      return {
        inputValue
      }
    },
    data () {
      return {
        isFocus: false,
        /** @type {unknown} */
        fieldType: null
      }
    },
    mounted () {
      this.fieldType = this.$attrs.type || 'text'
    },
    computed: {
      /**
       * @function labelValue
       * @returns {string}
       */
      labelValue () {
        if (this.label) {
          // @ts-ignore
          if (!this.isRequired && !this.ignoreOptional) return `${this.label} (${this.$options.filters.capitalize(this.$t('optional'))})`
        }

        return this.label
      },
      /**
       * @function hintValue
       * @returns {string}
       */
      hintValue () {
        if (this.hint && !this.error) {
          // @ts-ignore
          if (!this.isRequired && !this.ignoreOptional) return `${this.hint} (${this.$options.filters.capitalize(this.$t('optional'))})`
        }

        return this.hint
      },
      /**
       * Returns true if the field is disabled
       * @function isDisabled
       * @returns {boolean}
       */
      isDisabled () {
        return typeof this.$attrs.disabled !== 'undefined' && this.$attrs.disabled !== false
      },
      /**
       * Returns true if the field is required
       * @function isRequired
       * @returns {boolean}
       */
      isRequired () {
        return typeof this.$attrs.required !== 'undefined' && this.$attrs.required !== false
      },
      /**
       * Returns true if the field has the password toggle button
       * @function hasPasswordToggle
       * @returns {boolean}
       */
      hasPasswordToggle () {
        return !this.clearable && this.$attrs.type === 'password' && this.passwordToggle
      }
    },
    methods: {
      /**
       * Called whenever the user presses the clear button in the input
       * @function clear
       */
      clear () {
        this.$emit('input', null)
        this.$emit('clear')
      },
      /**
       * Switch the field type to toggle the password
       * @function togglePassword
       */
      togglePassword () {
        if (this.hasPasswordToggle) {
          this.fieldType = this.fieldType === 'text' ? 'password' : 'text'
        }
        this.$emit('toggle-password')
      },
      onFocus () {
        this.$emit('focus')
        this.isFocus = true
      },
      onBlur () {
        this.$emit('blur')
        this.isFocus = false
      },
      /**
       * @function onKeyUp
       * @param {Event} e
       */
      onKeyUp (e) {
        this.$emit('keyup', e)
      },
      focusInput () {
        // @ts-ignore
        this.$refs.CtkInputText.focus()
      }
    }
  })
</script>

<style lang="scss" scoped>
.ctk-input-text__field {
  transition-duration: 0.3s;
  height: 42px;
  min-height: 42px;
  outline: none;
  border: 1px solid #D2D2D2;
}
.ctk-input-text__field__label {
  top: 3px;
  left: 13px;
  transform: translateY(25%);
  opacity: 0;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 11px;
  color: rgba(0, 0, 0, 0.54);
  z-index: 1;
  width: 100%;
  width: calc(100% - 13px);
  pointer-events: none;
}
.ctk-input-text__field__label.has-clear {
  width: calc(100% - 13px - 24px);
}
.ctk-input-text__field__label--invalid {
  --tw-text-opacity: 1;
  color: rgba(225, 38, 28, var(--tw-text-opacity));
}
.ctk-input-text__field__input {
  background: transparent;
}
@media only screen and (max-width: $breakpoint-mobile-l) {
  .ctk-input-text__field__input {
    font-size: 1rem;
  }
}
.ctk-input-text__field__input.has-clear {
  width: calc(100% - 24px);
}
.ctk-input-text__field__input::-ms-clear {
  display: none;
}
.ctk-input-text__field__input::-webkit-search-decoration, .ctk-input-text__field__input::-webkit-search-cancel-button, .ctk-input-text__field__input::-webkit-search-results-button, .ctk-input-text__field__input::-webkit-search-results-decoration {
  appearance: none;
}
.ctk-input-text__field__input:focus {
  outline: none;
}
.ctk-input-text__field__clear, .ctk-input-text__field__toggle-password {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 8px;
  margin: auto 0;
  width: $clear-size;
  height: $clear-size;
  appearance: none;
  border: none;
  background: transparent;
  color: $third-text;
  border-radius: $clear-size;
  cursor: pointer;
}
.ctk-input-text__field__clear:focus, .ctk-input-text__field__toggle-password:focus {
  outline: none;
}
.ctk-input-text__field__clear .ctk-font, .ctk-input-text__field__toggle-password .ctk-font {
  font-size: 24px;
}
.ctk-input-text__field__clear .ctk-input-text__field__clear__icon, .ctk-input-text__field__clear > span:not(.ctk-input-text__field__toggle-password__effect) .ctk-font, .ctk-input-text__field__toggle-password .ctk-input-text__field__clear__icon, .ctk-input-text__field__toggle-password > span:not(.ctk-input-text__field__toggle-password__effect) .ctk-font {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.ctk-input-text__field__clear__effect, .ctk-input-text__field__toggle-password__effect {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: $clear-size;
  height: $clear-size;
  background-color: rgba(black, 0.54);
  border-radius: $clear-size;
  transform: scale(0);
  transition: transform 200ms;
}
.ctk-input-text__field__clear:hover, .ctk-input-text__field__toggle-password:hover {
  color: white;
}
.ctk-input-text__field__clear:hover .ctk-input-text__field__clear__effect, .ctk-input-text__field__clear:hover .ctk-input-text__field__toggle-password__effect, .ctk-input-text__field__toggle-password:hover .ctk-input-text__field__clear__effect, .ctk-input-text__field__toggle-password:hover .ctk-input-text__field__toggle-password__effect {
  transform: scale(1);
  opacity: 0.6;
}
.ctk-input-text__field__suffix {
  align-self: center;
}
.ctk-input-text__field.has-hint {
  padding-top: 14px;
}
.ctk-input-text__field.has-hint .ctk-input-text__field__label {
  opacity: 1;
  transform: translateY(0);
  font-size: 11px;
}
.ctk-input-text__field.has-value {
  padding-top: 14px;
}
.ctk-input-text__field.has-value .ctk-input-text__field__label {
  opacity: 1;
  transform: translateY(0);
  font-size: 11px;
}
.ctk-input-text__field.is-focused {
  border-color: $info;
}
.ctk-input-text__field.is-focused .ctk-input-text__field__label:not(.ctk-input-text__field__label--invalid) {
  color: $info;
}
.ctk-input-text__field.has-error {
  border-color: $danger !important;
}
.ctk-input-text__field.is-disabled, .ctk-input-text__field .ctk-input-text__field__input:disabled {
  cursor: not-allowed;
}
.ctk-input-text__field.is-disabled {
  background-color: rgba(black, 0.05);
}
.ctk-input-text__field .ctk-input-text__field__input:invalid, .ctk-input-text__field.has-error {
  box-shadow: none !important;
}
.ctk-input-text__field.has-prefix {
  padding-left: 35px;
}
.ctk-input-text__field.has-prefix .ctk-input-text__field__label {
  padding-left: 22px;
}
.loader {
  height: 2px;
  width: calc(100% - 24px);
  left: 12px;
}
.loader::before {
  display: block;
  position: absolute;
  content: '';
  left: -200px;
  width: 200px;
  height: 2px;
  background-color: rgba(black, 0.2);
  animation: loading 2s linear infinite;
}
@keyframes loading {
  from {
    left: -200px;
    width: 0;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}
</style>
