// @ts-nocheck
export default {
  getQuotation: state => state.quotation,
  getQuotationSelectedPrice: state => state.selectedPrice,
  getCustomPrice: state => state.customPrice,
  /**
   * @function isSelectedPriceNetwork
   * @returns {boolean}
   */
  isSelectedPriceNetwork: (state, getters) => getters.getQuotationSelectedPrice && getters.getQuotationSelectedPrice.type === 'pallet_network',
  isSelectedPriceCreditCard: (state, getters) => getters.getQuotationSelectedPrice && getters.getQuotationSelectedPrice.payment_plans.includes('immediate_cc'),
  isSelectedPriceCCAuthorization: (state, getters) => getters.getQuotationSelectedPrice && getters.getQuotationSelectedPrice.payment_plans.includes('immediate_cc_authorization'),
  isSelectedPriceThirtyDaysInvoice: (state, getters) => getters.getQuotationSelectedPrice && getters.getQuotationSelectedPrice.payment_plans.includes('thirty_days_invoice'),
  getPickupAddress (state) {
    return state.pickup.address
  },
  getDeliveryAddress (state) {
    return state.delivery.address
  },
  getPickupDate (state) {
    return state.pickup.timeslot.date
  },
  getDeliveryDate (state) {
    return state.delivery.timeslot.date
  },
  isPickupFlexible (state) {
    return state.pickup.timeslot.flexible
  },
  isDeliveryFlexible (state) {
    return state.delivery.timeslot.flexible
  },
  getLoad (state) {
    const load = Object.assign({}, state.load)

    /**
     * If the load does not have a quantity because of the multipallet,
     * makes the sum of all the pallets's quantities and returns it
     */
    let quantity
    if (load.quantity !== null && load.pallets && load.pallets.length > 0) {
      quantity = load.pallets
        .map(pallet => parseInt(pallet.quantity, 10))
        .reduce((a, b) => a + b, 0)
    }

    load.quantity = quantity
    return load
  },
  getPickupTimeslot (state) {
    return [state.pickup.timeslot.start_time, state.pickup.timeslot.end_time]
  },
  getDeliveryTimeslot (state) {
    return [
      state.delivery.timeslot.start_time,
      state.delivery.timeslot.end_time
    ]
  },
  getFetchedShipments: state => state.fetchedShipments,
  isQuotationCompleted (state, getters) {
    return !!getters.getQuotation && state.quotationValidated && state.guards.dates
  }
}
