import store from '@/store'
import Storage from '@/services/Storage'
import Hotjar from '@/plugins/VueHotjar'

import { isTesting } from '@/../utils/env'

export default async (to, from, next) => {
  const hasUserInfos = !!store.state.auth.userInfos

  if (to.meta.requiresAuth === false) {
    next()
    return
  }

  if (!hasUserInfos) {
    let token = null

    token = store.getters['auth/getToken']

    if (!token) {
      /**
       * Check if we already have a token in the localstorage.
       */
      const storageToken = Storage && Storage.getItem('userToken')
      if (storageToken) token = storageToken
    }

    /**
     * During browser tests, use the token from the query parameter
     * in priority if it's available.
     */
    if (isTesting && to.query.token) {
      token = to.query.token
    }

    if (token) {
      store.dispatch('auth/setToken', token)

      /**
       * We have a token in the localStorage, make a request to make
       * sure he's still valid
       */
      store.dispatch('auth/getMe')
        .then(async () => {
          await store.dispatch('auth/retrieveCompany')

          if (store.getters.isUserShipper) {
            store.dispatch('notificationsPooling')
            await store.dispatch('billing/retrieveInvoicesMetrics')
          }

          await store.dispatch('ui/retrieveAlerts')

          /**
           * Tag the Hotjar session for carriers that sign in for the
           * first time.
           */
          if (store.getters.isWelcomeVisible && store.getters.isUserShipper) {
            Hotjar.tag('New Shipper')
          }

          if (store.getters['auth/isFirstConnection']) {
            setTimeout(() => {
              store.dispatch('ui/setWelcomeVisible', true)
            }, 2000)
          }

          /**
           * Since the token is valid, and we're trying to go to an
           * non-required auth view, we want to redirect to the dashboard.
           * @example
           * The route is /auth and the user is already logged in.
           * Redirect him to the dashboard instead.
           */
          if (to.meta.requiresAuth === false) {
            next({
              name: 'Dashboard'
            })
          } else {
            next()
          }
        })
        .catch(() => {
          Storage && Storage.removeItem('userToken')
          store.dispatch('auth/setToken', null)

          /**
           * The token is not valid anymore, redirect the user to OUR login view.
           * EXCEPT if we're trying to access the login view
           */
          if (to.meta.requiresAuth === false) {
            next()
          } else {
            next({
              name: 'SignIn'
            })
          }
        })
    } else {
      next({
        name: 'SignIn'
      })
    }
  } else {
    next()
  }
}
