<template>
  <carousel
    ref="carousel"
    v-bind="$attrs"
    :navigate-to="page"
    :pagination-active-color="'#287696'"
    :pagination-color="'#e2e2e2'"
    :pagination-size="5"
    :pagination-padding="8"
    class="offers-suggestion-list tw-w-full"
  >
    <slide
      v-for="(offer, k) in getOffersSuggestions"
      :key="k"
      :class="inSidebar ? 'tw-pl-4 tw-pr-1' : 'tw-pr-4'"
      @slideClick="select(offer)"
    >
      <ctk-offer-card
        :offer="offer"
        :has-chips="true"
        class="tw-mb-2"
      >
        <template #chips>
          <div
            class="chips tw-flex"
          >
            <div
              class="chip tw-text-xs"
              v-text="$t(offer.suggest_direction === 'same_direction'
                ? 'offer_list.suggestions.same_direction'
                : 'offer_list.suggestions.opposite_direction')"
            />
          </div>
        </template>
      </ctk-offer-card>
    </slide>
  </carousel>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import { EventBus } from '@/services/EventBus'

  import CtkOfferCard from '@/components/CtkOfferCard/index.vue'

  /**
   * @module component - offersSuggestionList
   * @param {boolean} inSidebar - Specify if the list is inside the map sidebar
   */
  export default defineComponent({
    name: 'OffersSuggestionList',
    components: {
      CtkOfferCard
    },
    props: {
      inSidebar: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        page: 0
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ]),
      ...mapGetters('offers', [
        'getOffersSuggestions'
      ])
    },
    mounted () {
      this.$nextTick(() => {
        this.computeWidth()
      })
    },
    created () {
      /**
       * Update the carousel width from any exterior event in the app.
       */
      EventBus.$on('layout-update', () => {
        this.computeWidth()
      })
    },
    beforeDestroy () {
      EventBus.$off('layout-update')
    },
    methods: {
      ...mapActions('offers', [
        'setCurrentOffer',
        'trackOffer'
      ]),
      /**
       * @function computeWidth
       */
      computeWidth () {
        if (this.$refs.carousel) {
          this.page = 0
          this.$refs.carousel.computeCarouselWidth()
          this.$refs.carousel.render()
          this.page = 1
        }
      },
      /**
       * Called whenever the user selects an offer
       * @function select
       * @param {object} offer
       * @param {string} offer.uuid
       */
      select (offer) {
        this.setCurrentOffer(offer)
        this.trackOffer(offer.uuid)

        this.$emit('select', offer)
      }
    }
  })
</script>
