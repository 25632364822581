// @ts-nocheck

export default {
  SET_ACCOUNT (state, infos) {
    state.account = {
      ...state.account,
      ...infos
    }
  }
}
