<template>
  <ul
    :aria-hidden="!isOpen"
    :aria-labelledby="id"
    :aria-activedescendant="activeItem
      ? `${id}-item-${activeItem.value}`
      : null"
    ref="list"
    class="ctk-input-select-list"
    role="listbox"
    tabindex="-1"
    @blur="hide"
    @keydown="checkHide"
  >
    <li
      v-for="item in items"
      :key="item.value"
      :class="{
        selected: activeItem && activeItem.value === item.value,
        disabled: item.disabled,
        hovered: item.hovered
      }"
      :aria-selected="activeItem && activeItem.value === item.value"
      :id="`${id}-item-${item.value}`"
      :data-item-value="item.value"
      class="ctk-input-select-list__item"
      role="option"
      @click.stop="click(item)"
      @mouseover="$emit('mouseover-item', item)"
      @mouseout="$emit('mouseout-item', item)"
    >
      <slot
        :item="item"
        name="item"
      >
        {{ item.text }}
      </slot>
    </li>
  </ul>
</template>

<script>
  import { KEYCODES } from '@/composables/constants'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  /**
   * @typedef ListItem
   * @type {object}
   * @property {string} text
   * @property {any} value
   * @property {boolean} disabled
   * @property {?boolean} hovered
   */

  /**
   * @module component - CtkInputSelectList
   * @param {string} id
   * @param {Array<ListItem>} items
   */
  export default {
    name: 'CtkInputSelectList',
    props: {
      id: {
        type: String,
        required: true
      },
      items: {
        type: Array,
        required: true
      },
      value: {
        type: [String, Boolean, Number, Object],
        default: null
      },
      open: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const { state: isOpen } = useModelGetterSetter(props, 'open')

      return {
        isOpen
      }
    },
    data () {
      return {
        keysSoFar: '',
        keyClear: null,
        /** @type {?ListItem} */
        activeItem: null
      }
    },
    watch: {
      value (v) {
        // @ts-ignore
        this.activeItem = this.items.find(item => item.value === v)
      }
    },
    mounted () {
      /**
       * Set the current active item from the current value.
       */
      // @ts-ignore
      this.activeItem = this.items.find(item => item.value === this.value)
    },
    methods: {
      /**
       * @function click
       * @param {ListItem} item
       * @returns {any}
       */
      click (item) {
        if (item.disabled) return false

        this.hide()
        this.selectValue(item)
      },
      /**
       * @function selectValue
       * @param {ListItem} item
       */
      selectValue (item) {
        this.activeItem = item
        this.$emit('input', typeof item.value !== 'undefined' || item.value !== null ? item.value : this.value)
      },
      focus () {
        // @ts-ignore
        this.$refs.list.focus()
      },
      /**
       * If there is no active descendent, focus the first element
       * @function setupFocus
       */
      setupFocus () {
        /** @type {HTMLElement} */
        // @ts-ignore
        const list = this.$refs.list
        const activeDescendant = list.getAttribute('aria-activedescendant')
        if (activeDescendant) {
          return
        }

        this.focusFirstItem()
      },
      hide () {
        this.isOpen = false
      },
      /**
       * @function checkHide
       * @param {KeyboardEvent} e
       */
      checkHide (e) {
        const key = e.which || e.keyCode
        switch (key) {
        case KEYCODES.ESC:
        case KEYCODES.RETURN:
          e.preventDefault()
          this.hide()
          this.$emit('re-focus')
          break
        }

        this.keyPress(e)
      },
      /**
       * @function focusItem
       * @param {HTMLElement} element
       */
      focusItem (element) {
        const itemList = this.getItemFromNode(element)
        if (itemList) this.activeItem = itemList

        /** @type {HTMLElement} */
        // @ts-ignore
        const list = this.$refs.list
        if (list.scrollHeight > list.clientHeight) {
          const scrollBottom = list.clientHeight + list.scrollTop
          const elementBottom = element.offsetTop + element.offsetHeight

          if (elementBottom > scrollBottom) {
            list.scrollTop = elementBottom - list.clientHeight
          } else if (element.offsetTop < list.scrollTop) {
            list.scrollTop = element.offsetTop
          }
        }
      },
      focusLastItem () {
        /** @type {HTMLElement} */
        // @ts-ignore
        const list = this.$refs.list
        const itemList = list.querySelectorAll('[role="option"]:not(.disabled)')

        if (itemList.length) {
          // @ts-ignore
          this.focusItem(itemList[itemList.length - 1])
        }
      },
      focusFirstItem () {
        /** @type {HTMLElement} */
        // @ts-ignore
        const list = this.$refs.list
        const firstItem = list.querySelector('[role="option"]:not(.disabled)')

        if (firstItem) {
          // @ts-ignore
          this.focusItem(firstItem)
        }
      },
      /**
       * @function toggleSelectItem
       * @returns {any}
       */
      toggleSelectItem () {
        /** @type {?ListItem} */
        // @ts-ignore
        const itemList = this.items
          // @ts-ignore
          .find(item => item.value.toString() === this.activeItem.value.toString())

        if (itemList) {
          if (itemList.disabled) return false

          this.selectValue(itemList)
        }
      },
      clearKeysSoFarAfterDelay () {
        if (this.keyClear) {
          // @ts-ignore
          clearTimeout(this.keyClear)
          this.keyClear = null
        }

        // @ts-ignore
        this.keyClear = setTimeout(() => {
          this.keysSoFar = ''
          this.keyClear = null
        }, 500)
      },
      /**
       * @function findItem
       * @param {number} key
       */
      findItem (key) {
        /** @type {HTMLElement} */
        // @ts-ignore
        const list = this.$refs.list
        const itemList = list.querySelectorAll('[role="option"]:not(.disabled)')
        const activeDescendant = list.getAttribute('aria-activedescendant')
        const character = String.fromCharCode(key)
        let searchIndex = 0

        if (!this.keysSoFar) {
          for (var i = 0; i < itemList.length; i++) {
            if (itemList[i].getAttribute('id') === activeDescendant) {
              searchIndex = i
            }
          }
        }
        this.keysSoFar += character
        this.clearKeysSoFarAfterDelay()

        let nextMatch = this.findMatchInRange(itemList, searchIndex + 1, itemList.length)
        if (!nextMatch) {
          nextMatch = this.findMatchInRange(itemList, 0, searchIndex)
        }
        return nextMatch
      },
      /**
       * @param {NodeListOf<Element>} list
       * @param {number} startIndex
       * @param {number} endIndex
       */
      findMatchInRange (list, startIndex, endIndex) {
        // Find the first item starting with the keysSoFar substring, searching in
        // the specified range of items
        for (let n = startIndex; n < endIndex; n++) {
          // @ts-ignore
          const label = list[n].innerText
          if (label && label.toUpperCase().indexOf(this.keysSoFar) === 0) {
            return list[n]
          }
        }
        return null
      },
      /**
       * Returns the item object from the node itself.
       * It will returns false if the item is considered disabled.
       * @function getItemFromNode
       * @param {HTMLElement|Element} node
       * @returns {?ListItem}
       */
      getItemFromNode (node) {
        if (!node) return null

        const value = node.getAttribute('data-item-value')
        if (!value) return null
        /** @type {?ListItem} */
        // @ts-ignore
        const item = this.items
          // @ts-ignore
          .find(item => item.value.toString() === value.toString())

        if (!item) return null
        if (item.disabled) return null

        return item
      },
      /**
       * @function keyPress
       * @param {KeyboardEvent} e
       */
      async keyPress (e) {
        const key = e.which || e.keyCode
        /** @type {HTMLElement} */
        // @ts-ignore
        const list = this.$refs.list
        if (!list) return false

        await this.$nextTick()
        const activeDescendant = list.getAttribute('aria-activedescendant')

        /** @type {?Element} */
        let nextItem = null
        if (activeDescendant) {
          nextItem = document.getElementById(activeDescendant)
        }
        if (!nextItem) return false

        /**
         * Recursively retrieve an item that's not disabled.
         * If it reaches the end of the list, go to either the first or last element.
         * @function getPreviousElement
         * @returns {any}
         */
        const getPreviousElement = () => {
          if (!nextItem) return this.focusLastItem()
          nextItem = nextItem.previousElementSibling

          if (!nextItem) return getPreviousElement()

          // Check if the next item is disabled
          const item = this.getItemFromNode(nextItem)
          if (!item) {
            getPreviousElement()
          }
        }

        /**
         * @function getNextElement
         * @returns {any}
         */
        const getNextElement = () => {
          if (!nextItem) return this.focusFirstItem()
          nextItem = nextItem.nextElementSibling

          if (!nextItem) return getNextElement()

          // Check if the next item is disabled
          const item = this.getItemFromNode(nextItem)
          if (!item) {
            getNextElement()
          }
        }

        switch (key) {
        case KEYCODES.UP:
        case KEYCODES.DOWN:
          e.preventDefault()

          if (key === KEYCODES.UP) {
            getPreviousElement()
          } else {
            getNextElement()
          }

          if (nextItem) {
            // @ts-ignore
            this.focusItem(nextItem)
          }
          break
        case KEYCODES.HOME:
          e.preventDefault()
          this.focusFirstItem()
          break
        case KEYCODES.END:
          e.preventDefault()
          this.focusLastItem()
          break
        case KEYCODES.SPACE:
        case KEYCODES.RETURN:
          e.preventDefault()
          this.toggleSelectItem()
          this.hide()
          this.$emit('re-focus')
          break
        default: {
          const item = this.findItem(key)
          if (item) {
            // @ts-ignore
            this.focusItem(item)
          }
          break
        }
        }

        return true
      }
    }
  }
</script>

<style lang="scss" scoped>
.ctk-input-select-list {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  list-style-type: none;
  overflow: auto;
  padding: 0px;
  position: absolute;
  left: 0px;
  text-align: center;
  z-index: 20;
  max-height: 200px;
  box-shadow: 0 2px 2px 0 rgba(3, 3, 3, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  transform: translate3d(0, 0, 0);
}
.ctk-input-select-list:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.ctk-input-select-list__item {
  cursor: pointer;
  text-align: left;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px 10px;
}
.ctk-input-select-list__item:hover, .ctk-input-select-list__item.hovered {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 241, 243, var(--tw-bg-opacity));
}
.ctk-input-select-list__item.selected {
  --tw-bg-opacity: 1;
  background-color: rgba(39, 84, 145, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.ctk-input-select-list__item.disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.1;
  cursor: not-allowed;
  opacity: 0.5;
}
.ctk-input-select-list__item.disabled.selected {
  --tw-text-opacity: 1;
  color: rgba(2, 29, 73, var(--tw-text-opacity));
}
</style>
