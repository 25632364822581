<template>
  <portal to="dialogs">
    <transition
      :name="transitionName"
    >
      <div
        :class="$attrs['modal-class']"
        class="ctk-dialog tw-fixed tw-inset-0 tw-flex tw-w-full tw-h-full tw-overflow-y-hidden tw-z-100 tw-bg-black tw-bg-opacity-30"
        data-test="mask"
      >
        <div class="modal-wrapper tw-flex tw-flex-col tw-w-full tw-p-4 tw-justify-center tw-items-center tw-max-h-full tw-align-middle tw-h-auto">
          <div
            class="modal-container tw-bg-white tw-p-[40px] tw-mx-auto tw-my-0 tw-shadow-xl tw-h-full tw-max-h-full tw-w-full tw-transform tw-overflow-y-hidden tw-text-secondary-lighten tw-border tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-accent tw-border-[20px] md:tw-max-w-[620px] md:tw-max-h-[670px] !tw-h-auto"
            data-test="container"
          >
            <header
              class="modal-header tw-flex tw-flex-col tw-justify-start tw-items-center"
              data-test="header"
            >
              <img
                src="@/assets/img/icons/tos-check.svg"
                height="82"
                alt="icon"
                data-test="header__icon"
              >

              <div
                class="tw-py-6 tw-flex tw-flex-col tw-justify-start tw-items-center text-center"
                data-test="header__title__wrapper"
              >
                <h1
                  v-text="title"
                  class="tw-text-modal-title tw-font-condensed tw-m-0  tw-text-[22px] tw-leading-[26px]"
                  data-test="title"
                />
                <p
                  v-if="editDate"
                  v-text="editDate"
                  class="tw-mt-1 tw-mb-0 tw-text-xs tw-leading-[20px] tw-text-secondary-text"
                  data-test="edit-date"
                />
              </div>
            </header>

            <!-- body -->
            <div
              class="modal-content tw-p-4 tw-bg-gray-100 tw-overflow-y-auto"
            >
              <!-- cgu content -->
              <div
                v-if="content"
                v-markdown="content"
                class="modal-content__text tw-h-[255px] tw-bg-transparent"
                data-test="content"
              />
            </div>

            <footer
              class="modal-footer tw-pt-4 tw-flex tw-flex-col tw-justify-start"
              :class="content ? 'tw-items-start' : 'tw-items-center'"
              data-test="footer"
            >
              <div class="tw-flex tw-justify-start tw-items-center tw-gap-x-2">
                <b-form-checkbox
                  v-model="isTosAccepted"
                  :disabled="wait.is('sending tos acceptance')"
                  :class="{
                    'tw-opacity-75': wait.is('sending tos acceptance')
                  }"
                  class="tw-mb-2"
                  @change="onChange($event)"
                  data-test="accept-tos-check"
                >
                  <span
                    v-text="tosCheckboxPrefix"
                  />&nbsp;<a
                    :href="tosLink"
                    target="_blank"
                    v-text="tosCheckboxLinkText"
                    class="tw-text-blue-500 hover:tw-text-blue-600"
                    data-test="accept-tos-check-link"
                  />
                </b-form-checkbox>
              </div>

              <UiButton
                :disabled="!isTosAccepted || wait.is('sending tos acceptance')"
                :loading="wait.is('sending tos acceptance')"
                class="tw-w-full md:tw-w-auto md:tw-mt-[20px] md:tw-mt-0 tw-w-full md:tw-w-auto tw-mx-auto"
                variant="primary"
                @click="submitTosAcceptance"
                data-test="accept-tos-btn"
              >
                {{ $t('app.tos.button.ok') | capitalize }}
              </UiButton>
            </footer>
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
  import { defineComponent, ref } from '@vue/composition-api'

  import useI18n from '@/composables/useI18n'
  import useMoment from '@/composables/useMoment'
  import useWait from '@/composables/useWait'
  import useStore from '@/composables/useStore'

  import { Tos } from '@/resources'

  import Config from '@/services/Config'

  import UiButton from '@/components/UI/Button/index.vue'

  export default defineComponent({
    name: 'CtkCguAcceptanceDialog',
    components: {
      UiButton
    },
    props: {
      fetchCgu: {
        type: Boolean,
        default: false
      },
      transitionName: {
        type: String,
        default: 'modal'
      }
    },
    setup (props) {
      const i18n = useI18n()
      const { value: moment } = useMoment()
      const { value: store } = useStore()
      const wait = useWait()

      const title = i18n.value.t('app.tos.titles.main')
      const tosCheckboxPrefix = i18n.value.t('app.tos.labels.acceptance.prefix')
      const tosCheckboxLinkText = i18n.value.t('app.tos.labels.acceptance.link_text')
      const tosLink = `${Config.get('tos')}`

      const content = ref(null)
      const isTosAccepted = ref(false)
      const editDate = ref(null)
      const tosUuid = ref(null)

      const onChange = (value) => {
        isTosAccepted.value = value
      }

      const submitTosAcceptance = async () => {
        wait.start('sending tos acceptance')

        Tos.accept({
          uuid: tosUuid.value
        })
          .then(() => {
            store.dispatch('cgu/setRequiresUpdate', false)
          })
          .catch(err => {
            console.log('an error occured during tos acceptance', { err })
          // TODO: log error to sentry
          // TODO: show error to the user
          })
          .finally(() => {
            wait.end('sending tos acceptance')
          })
      }

      if (props.fetchCgu === true) {
        const userInfo = store.getters['auth/getUserInfos']
        Tos.getContent({
          locale: userInfo.locale
        })
          .then(({ status, data }) => {
            if (status === 200) {
              content.value = data.content
              editDate.value = i18n.value.t('app.tos.updated_at', { date: moment(data.publication_date).format('L') })
              tosUuid.value = data.uuid
            }
          })
          .catch(({ message }) => {
            console.log('an error occured during TOS rendering', { message })
          })
      }

      return {
        isTosAccepted,
        title,
        content,
        editDate,
        tosCheckboxPrefix,
        tosCheckboxLinkText,
        tosLink,
        wait,
        onChange,
        submitTosAcceptance
      }
    }
  })
</script>

<style lang="scss" scoped>

  .modal {
    &-container {
      display: flex;
      flex-direction: column;
      min-height: 400px;

      > * {
        border: none;
      }
    }

    &-header,
    &-footer {
      flex-grow: 0;
      flex-shrink: 0;
    }

    &-content {
      flex: 1 1 280px;
      min-height: 100px;

      @media only screen and (min-width: 700px) and (max-height: 500px) {
        display: none;
      }
    }

    &-footer {
      @media only screen and (min-width: 700px) and (max-height: 500px) {
        align-items: center;
      }
    }
  }

</style>
