var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ctk-input-text"},[_c('div',{ref:"parent",staticClass:"ctk-input-text__field tw-font-normal tw-flex tw-relative tw-appearance-none tw-bg-white tw-w-full tw-px-3 tw-py-0",class:{
      'is-disabled': _vm.isDisabled,
      'is-focused': _vm.isFocus || _vm.valid,
      'has-value': _vm.value,
      'has-error': _vm.error,
      'has-hint': _vm.hint && !_vm.value,
      'has-prefix': _vm.hasPrefix
    },attrs:{"data-id":_vm.id},on:{"click":_vm.focusInput}},[(_vm.hasPrefix)?_c('div',{staticClass:"ctk-input-text__field__prefix tw-absolute tw-left-0 tw-top-0 tw-bottom-0 tw-my-auto tw-mx-0 tw-flex tw-items-center flex-fixed"},[_vm._t("prefix")],2):_vm._e(),_c('div',{staticClass:"tw-flex-1 tw-h-full"},[_c('label',{staticClass:"ctk-input-text__field__label tw-truncate tw-absolute",class:{
          'ctk-input-text__field__label--invalid' : _vm.error,
          'has-clear': _vm.clearable
        },attrs:{"for":_vm.id,"title":_vm.hintValue || _vm.labelValue}},[_vm._v(" "+_vm._s(_vm.hintValue || _vm.labelValue)+" ")]),((_vm.fieldType)==='checkbox')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"CtkInputText",staticClass:"ctk-input-text__field__input tw-appearance-none tw-border-none tw-h-full tw-w-full tw-p-0",class:{
          'has-clear': _vm.clearable
        },attrs:{"id":_vm.id,"placeholder":_vm.labelValue,"required":_vm.isRequired,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputValue)?_vm._i(_vm.inputValue,null)>-1:(_vm.inputValue)},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"keyup":_vm.onKeyUp,"change":function($event){var $$a=_vm.inputValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputValue=$$c}}}},'input',_vm.$attrs,false)):((_vm.fieldType)==='radio')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"CtkInputText",staticClass:"ctk-input-text__field__input tw-appearance-none tw-border-none tw-h-full tw-w-full tw-p-0",class:{
          'has-clear': _vm.clearable
        },attrs:{"id":_vm.id,"placeholder":_vm.labelValue,"required":_vm.isRequired,"type":"radio"},domProps:{"checked":_vm._q(_vm.inputValue,null)},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"keyup":_vm.onKeyUp,"change":function($event){_vm.inputValue=null}}},'input',_vm.$attrs,false)):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"CtkInputText",staticClass:"ctk-input-text__field__input tw-appearance-none tw-border-none tw-h-full tw-w-full tw-p-0",class:{
          'has-clear': _vm.clearable
        },attrs:{"id":_vm.id,"placeholder":_vm.labelValue,"required":_vm.isRequired,"type":_vm.fieldType},domProps:{"value":(_vm.inputValue)},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"keyup":_vm.onKeyUp,"input":function($event){if($event.target.composing){ return; }_vm.inputValue=$event.target.value}}},'input',_vm.$attrs,false)),_c('transition',{attrs:{"name":"fade"}},[(_vm.hasPasswordToggle)?_c('button',{staticClass:"ctk-input-text__field__toggle-password",attrs:{"title":_vm.$t(_vm.fieldType === 'text'
            ? 'app.buttons.hide_password'
            : 'app.buttons.view_password'),"tabindex":"-1","type":"button"},on:{"click":_vm.togglePassword}},[_c('span',{staticClass:"ctk-input-text__field__toggle-password__effect"}),_c('span',[_c('ui-ctk-icon',{attrs:{"name":_vm.fieldType === 'text' ? 'hide' : 'show'}})],1)]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.clearable && _vm.inputValue && !_vm.isDisabled)?_c('button',{staticClass:"ctk-input-text__field__clear",attrs:{"title":_vm.$t('app.buttons.reset-input'),"tabindex":"-1","type":"button"},on:{"click":_vm.clear}},[_c('span',{staticClass:"ctk-input-text__field__clear__effect"}),_c('ui-ctk-icon',{staticClass:"ctk-input-text__field__clear__icon",attrs:{"name":"close"}})],1):_vm._e()])],1),(_vm.suffix)?_c('div',{staticClass:"ctk-input-text__field__suffix tw-text-xs tw-text-gray-600"},[_vm._v(" "+_vm._s(_vm.suffix)+" ")]):_vm._e(),(_vm.loader)?_c('div',{staticClass:"loader tw-absolute tw-bottom-0 tw-w-full tw-overflow-hidden"}):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }