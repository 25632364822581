<template>
  <ui-button
    :title="$t('close') | capitalize"
    type="button"
    variant="link"
    class="ctk-dialog-close-button !tw-absolute"
  >
    <template #left-icon>
      <ui-ctk-icon
        name="close"
        data-test="icon"
      />
    </template>
  </ui-button>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * Generic component for a close button used in the dialogs.
   * @module component - CtkDialogCloseButton
   */
  export default defineComponent({
    name: 'CtkDialogCloseButton'
  })
</script>

<style lang="scss" scoped>
.ctk-dialog-close-button {
  background-color: transparent;
  z-index: 1;
}
.ctk-dialog-close-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 244, 246, var(--tw-bg-opacity));
}
</style>
