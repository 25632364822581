var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",attrs:{"slim":""}},[_c('form',{staticClass:"add-driver-form tw-flex tw-flex-col",on:{"submit":function($event){$event.preventDefault();return _vm.saveDriver.apply(null, arguments)}}},[_c('div',{staticClass:"add-driver-form__content tw-flex-1"},[_c('div',{staticClass:"tw-flex tw-flex-col md:tw-flex-row tw-mb-4"},[_c('div',{staticClass:"tw-flex-1 md:tw-pr-2 tw-mb-4 md:tw-mb-0"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('firstname'),"rules":"required|min:1|max:255","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{attrs:{"id":"firstname","hint":invalid ? errors[0] : null,"error":invalid && validated,"label":_vm._f("capitalize")(_vm.$t('firstname')),"name":"first_name","type":"text","autocomplete":"given-name","required":""},model:{value:(_vm.formData.firstName),callback:function ($$v) {_vm.$set(_vm.formData, "firstName", $$v)},expression:"formData.firstName"}})]}}])})],1),_c('div',{staticClass:"tw-flex-1 md:tw-pl-2"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('name'),"rules":"required|min:1|max:255","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{attrs:{"id":"lastname","hint":invalid ? errors[0] : null,"error":invalid && validated,"label":_vm._f("capitalize")(_vm.$t('name')),"name":"last_name","type":"text","autocomplete":"family-name","required":""},model:{value:(_vm.formData.lastName),callback:function ($$v) {_vm.$set(_vm.formData, "lastName", $$v)},expression:"formData.lastName"}})]}}])})],1)]),_c('div',{staticClass:"tw-flex"},[_c('div',{staticClass:"tw-flex-1"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('phone'),"rules":("telephone:" + _vm.country),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-phone-number',{attrs:{"id":"PhoneNumber","country":_vm.country,"hint":invalid ? errors[0] : null,"error":invalid && validated,"name":"phone","autocomplete":"tel-national"},on:{"update:country":function($event){_vm.country=$event},"update":_vm.phoneUpdated},model:{value:(_vm.formData.phoneNumber),callback:function ($$v) {_vm.$set(_vm.formData, "phoneNumber", $$v)},expression:"formData.phoneNumber"}})]}}])})],1)]),(_vm.errorMessage)?_c('div',{staticClass:"error-container tw-text-red-500 py-2 px-3 mt-3",staticStyle:{"border":"1px solid"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()]),_c('div',{staticClass:"add-driver-form__footer tw-flex tw-flex-col-reverse md:tw-flex-row tw-items-center tw-w-full tw-py-3 tw-mt-4",class:_vm.hasBackButton ? 'tw-justify-between' : 'tw-justify-end'},[(_vm.hasBackButton)?_c('ui-button',{staticClass:"tw-mt-4 md:tw-mt-0 tw-w-full md:tw-w-auto",attrs:{"type":"button","variant":"link"},on:{"click":function($event){return _vm.$emit(_vm.summaryOptionsNeeded.length ? 'back' : 'close')}}},[_vm._v(" "+_vm._s(_vm._f("capitalize")((_vm.summaryOptionsNeeded.length ? _vm.$t('back') : _vm.$t('cancel'))))+" ")]):_vm._e(),_c('ui-button',{staticClass:"tw-w-full md:tw-w-auto",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.textButton)+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }