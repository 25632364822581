<template>
  <div class="ctk-input-textarea tw-truncate">
    <div
      ref="parent"
      :class="{
        'is-focused': isFocus,
        'has-value': inputValue,
        'is-disabled': isDisabled,
        'has-error': error
      }"
      class="field tw-flex tw-flex-col tw-truncate"
    >
      <label
        :for="$attrs.id || 'ctk-input-textarea'"
        class="field-label tw-truncate tw-flex-shrink-0"
        v-text="labelValue"
      />
      <textarea
        :id="$attrs.id || 'ctk-input-textarea'"
        ref="ctkInputTextarea"
        :disabled="isDisabled"
        :required="isRequired"
        v-model="inputValue"
        v-bind="$attrs"
        class="field-input tw-flex-grow"
        @focus="isFocus = true"
        @blur="isFocus = false"
      />
    </div>
    <div
      :class="error ? 'tw-text-red-500' : 'tw-text-blue-500'"
      v-text="hint"
      class="hint-text"
    />
  </div>
</template>

<script>
  import { defineComponent, ref } from '@vue/composition-api'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  export default defineComponent({
    name: 'CtkInputTextarea',
    inheritAttrs: false,
    props: {
      label: {
        type: String,
        default: 'Label'
      },
      value: {
        type: null,
        default: null,
        required: false
      },
      hint: {
        type: String,
        default: null
      },
      error: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        default: null
      },
      ignoreOptional: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const isFocus = ref(false)
      const { state: inputValue } = useModelGetterSetter(props, 'value')

      return {
        isFocus,
        inputValue
      }
    },
    computed: {
      /**
       * @function labelValue
       * @returns {string}
       */
      labelValue () {
        if (this.label) {
          // @ts-ignore
          if (!this.isRequired && !this.ignoreOptional) return `${this.label} (${this.$options.filters.capitalize(this.$t('optional'))})`
        }

        return this.label
      },
      /**
       * Returns true if the field is disabled
       * @function isDisabled
       * @returns {boolean}
       */
      isDisabled () {
        return typeof this.$attrs.disabled !== 'undefined' && this.$attrs.disabled !== false
      },
      /**
       * Returns true if the field is required
       * @function isRequired
       * @returns {boolean}
       */
      isRequired () {
        return typeof this.$attrs.required !== 'undefined' && this.$attrs.required !== false
      }
    }
  })
</script>

<style lang="scss" scoped>

  .field {
    position: relative;
    transition-duration: 0.3s;
    width: 100%;
    min-height: 78px;
    padding: 8px 12px 0 12px;
    font-weight: 400;
    appearance: none;
    outline: none;
    background-color: white;
    border: 1px solid #D2D2D2;
    border-radius: 0;

    &.has-error {
      border-color: $danger !important;
    }

    .field-label {
      position: absolute;
      top: 5px;
      left: 13px;
      transform: translateY(25%);
      transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
      font-size: 14px;
      color: rgba(0, 0, 0, 0.54);
      z-index: 1;
      width: 100%;
      width: calc(100% - 24px);
    }

    .field-input {
      border: none;
      appearance: none;
      background: transparent;
      padding: 0;
      height: 100%;
      width: 100%;
      border-radius: 0;

      &:focus {
        outline: none;
      }

      &:-moz-ui-invalid,
      &:invalid {
        box-shadow: none;
      }

      @media only screen and (max-width: $breakpoint-mobile-l) {
        font-size: 1rem;
      }
    }

    &.has-value {
      padding-top: 20px;

      .field-label {
        transform: translateY(0);
        font-size: 11px;
      }
    }

    &.is-disabled {
      &,
      .field-label,
      .field-input {
        cursor: not-allowed;
      }

      border-color: #CCC;
      background-color: rgba(black, 0.05);
    }

    &.is-focused {
      border-color: $info;
    }

    &.is-focused.has-value {
      .field-label {
        color: $info;
      }
    }
  }

</style>
