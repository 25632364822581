var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ctk-nav-bar-actions tw-flex tw-flex-col md:tw-flex-row"},[_c('router-link',{staticClass:"ctk-nav-bar-actions__button tw-mb-2 md:tw-mb-0 hover:tw-no-underline",attrs:{"to":{
      name: 'Faq'
    }},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_c('ui-ctk-icon',{attrs:{"name":"help"}}),_c('span',{staticClass:"tw-hidden lg:tw-block",domProps:{"textContent":_vm._s(_vm.$options.filters.capitalize(_vm.$t('navigation.faq')))}})],1),[_c('button',{directives:[{name:"matomo",rawName:"v-matomo",value:({
        click: {
          category: 'Shipments',
          action: 'Initiated Search'
        }
      }),expression:"{\n        click: {\n          category: 'Shipments',\n          action: 'Initiated Search'\n        }\n      }"}],ref:"search-button",staticClass:"ctk-nav-bar-actions__button tw-mb-2 md:tw-mb-0",attrs:{"title":_vm.$t('app.buttons.search'),"type":"button"},on:{"click":_vm.search}},[_c('ui-ctk-icon',{attrs:{"name":"search"}}),_c('span',{staticClass:"tw-hidden",domProps:{"textContent":_vm._s(_vm.$t('app.buttons.search'))}})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }