// @ts-nocheck
import AxiosResource from './AxiosResource'

export default class CompanyResource extends AxiosResource {
  constructor () {
    super()

    this.path = '/v2/companies/{cid}'
  }

  name (...args) {
    return this.action('PUT', '/name', ...args)
  }

  address (...args) {
    return this.action('PUT', '/address', ...args)
  }

  billingEmail (...args) {
    return this.action('PUT', '/billing-email', ...args)
  }

  deleteBillingEmail (...args) {
    return this.action('DELETE', '/billing-email', ...args)
  }

  phones (...args) {
    return this.action('GET', '/contact-phone-numbers', ...args)
  }

  vatNumber (...args) {
    return this.action('POST', '/vat-number', ...args)
  }

  statistics (...args) {
    return this.action('GET', '/metrics/revenues', ...args)
  }

  alerts (...args) {
    return this.action('GET', '/alerts', ...args)
  }

  validateEmail (...args) {
    return this.action('POST', '/user-email-validation', ...args)
  }

  updateEmail (...args) {
    return this.action('PUT', '/user-email', ...args)
  }

  metrics (...args) {
    return this.action('GET', '/metrics/dashboard', ...args)
  }
}
