<template>
  <div
    class="tw-w-9 tw-h-9 tw-rounded-full tw-flex tw-justify-center tw-items-center"
    :class="{
      'tw-bg-[#F5F5F5]': isCevaLogistics,
      'tw-bg-[#96BF31]': isChronotruck
    }"
    v-b-tooltip.hover
    :title="$t('offers.subsidiary', {'subsidiary': subsidiaryLabel})"
  >
    <img
      v-if="isCevaLogistics"
      src="@/assets/img/ceva_icon.svg"
      alt="CEVA Logistics"
      class="tw-w-5"
    >
    <img
      v-if="isChronotruck"
      src="@/assets/img/logo-icon-white.png"
      alt="Chronotruck"
      class="tw-w-5"
    >
  </div>
</template>

<script>
  import { computed, defineComponent } from '@vue/composition-api'

  export default defineComponent({
    name: 'CtkSubsidiary',
    props: {
      subsidiary: {
        type: String,
        required: true
      }
    },
    setup: function (props) {
      const isCevaLogistics = computed(() => props.subsidiary === 'ceva_logistics_france')
      const isChronotruck = computed(() => props.subsidiary === 'chronotruck_sa')
      const subsidiaryLabel = computed(() => {
        if (isCevaLogistics.value) {
          return 'CEVA Logistics France'
        }

        if (isChronotruck.value) {
          return 'Chronotruck SA'
        }

        return 'Unknown'
      })

      return {
        isCevaLogistics,
        isChronotruck,
        subsidiaryLabel
      }
    }
  })
</script>
