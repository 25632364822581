<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * Link component wrapping anchor & router-link.
   * @module component - UiLink
   */
  export default defineComponent({
    name: 'UiLink',
    inheritAttrs: false,
    props: {
      to: {
        type: [Object, String],
        default: null
      },
      replace: {
        type: Boolean,
        default: false
      },
      standalone: {
        type: Boolean,
        default: false
      }
    },
    render (createElement) {
      const _this = this

      const componentType = this.to
        ? 'router-link'
        : 'a'

      /**
       * @function hasSlot
       * @param {string} slotName
       * @returns {boolean}
       */
      function hasSlot (slotName) {
        // @ts-ignore
        return _this.$slots[slotName] && !!_this.$slots[slotName]
      }

      const hasContent = hasSlot('default')
      const hasLeftIcon = hasSlot('left-icon')
      const hasRightIcon = hasSlot('right-icon')

      const classes = []
      if (hasContent) classes.push('ui-link--content')
      if (hasLeftIcon) classes.push('ui-link--left-icon')
      if (hasRightIcon) classes.push('ui-link--right-icon')
      if (this.standalone) classes.push('ui-link--standalone')

      const componentProps = {}
      if (this.to) componentProps.to = this.to
      if (this.replace) componentProps.replace = this.replace

      const container = createElement('span', {
        staticClass: 'ui-link__container'
      }, [
        hasContent && this.$slots.default
      ])

      return createElement(componentType, {
        class: classes,
        staticClass: `ui-link ${this.$attrs.class || ''}`,
        props: {
          ...componentProps
        },
        attrs: this.$attrs,
        on: this.$listeners
      }, [
        hasLeftIcon && this.$slots['left-icon'],
        container,
        hasRightIcon && this.$slots['right-icon']
      ])
    }
  })
</script>

<style lang="scss">
.ui-link {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  --tw-text-opacity: 1;
  color: rgba(39, 84, 145, var(--tw-text-opacity));
}
.ui-link:hover {
  --tw-text-opacity: 1;
  color: rgba(29, 66, 137, var(--tw-text-opacity));
  text-decoration: none;
}
.ui-link:hover:not(.ui-link--left-icon):not(.ui-link--right-icon) {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.ui-link:not(.ui-link--standalone) {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.ui-link:disabled, .ui-link[disabled=disabled] {
  --tw-text-opacity: 0.6;
}
.ui-link--left-icon:hover .ui-link__container, .ui-link--right-icon:hover .ui-link__container {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.ui-link--left-icon .ctk-font, .ui-link--right-icon .ctk-font {
  width: 20px;
  height: 20px;
}
.ui-link--left-icon .material-icons, .ui-link--right-icon .material-icons {
  font-size: 20px;
}
.ui-link--left-icon .ctk-font::before, .ui-link--right-icon .ctk-font::before {
  position: relative;
}
.ui-link--left-icon.ui-link--content .ui-icon:first-child {
  margin-right: 0.75rem;
}
.ui-link--right-icon.ui-link--content .ui-icon:last-child {
  margin-left: 0.75rem;
}
.ui-link .ui-icon, .ui-link .ui-icon:hover {
  text-decoration: none;
}
</style>
