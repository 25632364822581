<template>
  <div
    class="navbar-user-navigation tw-p-8 tw-shadow-xl tw-bg-white tw-text-gray-800"
  >
    <ctk-user-info
      class="navbar-user-navigation__header tw-pb-4"
    />

    <div class="navbar-user-navigation__buttons tw-mb-4 tw-pb-4">
      <UiButton
        v-if="!getImpersonate"
        :to="{
          name: 'AccountProfile'
        }"
        data-test="account-button"
        class="tw-mr-2"
        size="sm"
        variant="info"
        @click.native="close"
      >
        {{ $t('navigation.account') }}
      </UiButton>
    </div>

    <nav class="navbar-user-navigation__links">
      <ul>
        <li>
          <UiLink
            v-if="isImpersonating"
            :href="getRedirectUrl"
            data-test="redirectUrl-link"
            class="tw-uppercase tw-inline-block tw-py-2"
            standalone
          >
            {{ $t('navigation.sign_out.isImpersonating') }}
          </UiLink>
          <UiLink
            v-else
            href="#"
            @click.prevent="logOut"
            data-test="logout-link"
            class="tw-uppercase tw-inline-block tw-py-2"
            standalone
          >
            {{ $t('navigation.sign_out') }}
          </UiLink>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import AuthService from '@/services/AuthService'

  import CtkUserInfo from '@/components/CtkUser/CtkUserInfo/index.vue'

  /**
   * Navigation menu under the user informations, focused on the user.
   * @module component - navbarUserNavigation
   * @emits close
   */
  export default {
    name: 'NavbarUserNavigation',
    components: {
      CtkUserInfo
    },
    computed: {
      ...mapGetters('auth', [
        'getImpersonate',
        'isImpersonating',
        'getRedirectUrl'
      ])
    },
    methods: {
      /**
       * Closes the dropdown menu
       * @function close
       */
      close () {
        this.$emit('close')
      },
      /**
       * Request auth logout
       * @function logOut
       * @param {Event} e
       */
      logOut (e) {
        AuthService.signOut(() => {
          this.close()
        })
        e.preventDefault()
      }
    }
  }
</script>

<style lang="scss" scoped>

  .navbar-user-navigation {
    min-width: 350px;
    max-width: 400px;

    &::before {
      position: absolute;
      top: 0;
      right: 16px;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 10px 5px;
      border-color: transparent transparent white transparent;
    }

    &__buttons {
      border-bottom: 1px solid $light-gray;
    }

    &__links {
      ul,
      li {
        list-style: none;
        margin: 0;
        padding: 0;
      }
    }

    @media only screen and (max-width: $breakpoint-mobile-l) {
      min-width: 100%;
      max-width: 100%;
      width: 100%;
      left: 0;
    }
  }

</style>
