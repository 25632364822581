<template>
  <div class="choose-driver-list">
    <div
      v-if="driversCount > 6"
      class="tw-pb-3 tw-flex-shrink-0"
      data-test="search"
    >
      <ctk-input-text
        id="search-driver"
        v-model="query"
        :label="$t('search_a_driver')"
        ignore-optional
        type="search"
        name="search"
        data-test="search-field"
      />
    </div>
    <div
      class="choose-driver-list__items tw-flex-1 tw-flex tw-flex-col tw-relative tw-overflow-auto"
      data-test="list"
    >
      <choose-driver-list-item
        v-for="driver in filteredDrivers"
        :key="driver.uuid"
        :driver="driver"
        :active="selectedDriver === driver"
        class="tw-w-full tw-mb-3"
        @click.native="selectedDriver = driver"
      />
      <div
        v-if="filteredDrivers.length === 0"
        key="empty"
        class="tw-p-4"
        data-test="empty-results"
      >
        <p
          class="tw-text-gray-600 tw-text-center tw-m-0"
          data-test="empty-results-message"
          v-text="query
            ? $t('no_driver_available_with_x', { query })
            : $t('app.labels.no_drivers')"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, defineComponent, ref, toRefs } from '@vue/composition-api'
  import SmartSearch from '@/services/SmartSearch'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import ChooseDriverListItem from './_subs/ChooseDriverListItem/index.vue'
  /**
   * @module component - ChooseDriverList
   */
  export default defineComponent({
    name: 'ChooseDriverList',
    components: {
      CtkInputText,
      ChooseDriverListItem
    },
    props: {
      value: {
        type: Object,
        default: null
      },
      drivers: {
        type: Array,
        default: () => ([])
      },
      driversCount: {
        type: Number,
        default: 0
      }
    },
    setup (props) {
      const { drivers } = toRefs(props)
      const query = ref(null)
      const { state: selectedDriver } = useModelGetterSetter(props, 'value')
      const filteredDrivers = computed(() => {
        const q = (query.value || '').trim()
        // @ts-ignore
        const entries = drivers.value.map(driver => ({
          name: driver.fullName,
          uuid: driver.uuid
        }))
        const fields = ['name']
        const results = SmartSearch(entries, [q], fields)
        if (q === '' || query === null) {
          return drivers.value
        }
        // @ts-ignore
        return drivers.value.filter(driver => results
          .findIndex(resultDriver => resultDriver.entry.uuid === driver.uuid) !== -1)
      })
      return {
        selectedDriver,
        query,
        filteredDrivers
      }
    }
  })
</script>
