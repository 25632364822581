// @ts-nocheck
import { Shipment } from '@/resources'

export default {
  setAddress ({ commit }, payload) {
    commit('SET_ADDRESS', payload)
  },
  setContact ({ commit }, payload) {
    commit('SET_CONTACT', payload)
  },
  setComment ({ commit }, payload) {
    commit('SET_COMMENT', payload)
  },
  setMeeting ({ commit }, payload) {
    commit('SET_MEETING', payload)
  },
  setTimeslot ({ commit }, payload) {
    commit('SET_TIME_SLOT', payload)
  },
  setGoods ({ commit }, payload) {
    commit('SET_GOODS', payload)
  },
  setHandlingDriver ({ commit }, payload) {
    commit('SET_HANDLING_DRIVER', payload)
  },
  setHandlingTailLift ({ commit }, payload) {
    commit('SET_HANDLING_TAIL_LIFT', payload)
  },
  setGuard ({ commit }, payload) {
    commit('SET_GUARD', payload)
  },
  setExpirationDate ({ commit }, date) {
    commit('SET_EXPIRATION_DATE', date)
  },
  setShipperReference ({ commit }, reference) {
    commit('SET_SHIPPER_REFERENCE', reference)
  },
  setConfirmationStep ({ commit }, value) {
    commit('SET_CONFIRMATION_STEP', value)
  },
  setPhoneNumber ({ commit }, phone) {
    commit('SET_PHONE_NUMBER', phone)
  },
  retrieveShipments ({ rootGetters, commit }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      let shipments = []
      let page = 1
      function reduceShipmentsList () {
        return shipments
          .reduce((unique, item) => {
            const hasShipment = unique
              .findIndex(shipment => {
                const { pickup, delivery, load } = shipment
                const { pickup: itemPickup, delivery: itemDelivery, load: itemLoad } = item

                /**
                 * This is dirty and resource consuming but do the job for the moment.
                 * TODO: Find a better way to compare those informations
                 */
                const samePickupAddress = JSON.stringify(pickup.address) === JSON.stringify(itemPickup.address)
                const sameDeliveryAddress = JSON.stringify(delivery.address) === JSON.stringify(itemDelivery.address)
                const sameLoad = JSON.stringify(load) === JSON.stringify(itemLoad)
                return samePickupAddress && sameDeliveryAddress && sameLoad
              })

            return hasShipment !== -1 ? unique : [...unique, item]
          }, [])
          .slice(0, 20)
      }

      function fetchShipment () {
        return Shipment.get({
          cid: rootGetters['auth/getCid']
        }, {
          params: {
            limit: 20,
            page
          }
        })
          .then(async ({ data }) => {
            shipments = [
              ...shipments,
              ...data.items
            ]

            shipments = reduceShipmentsList()

            const pagination = data.meta.pagination
            if (shipments.length < 20 && pagination.current_page < pagination.page_count) {
              page += 1
              await fetchShipment()
            }
          })
      }

      await fetchShipment()
      commit('SET_FETCHED_SHIPMENTS', shipments)
      resolve(shipments)
    })
  },
  setPallets ({ commit }, pallets) {
    commit('SET_PALLETS', pallets)
  }
}
