import Vue from 'vue'

import UiButton from './UI/Button'
import UiLink from './UI/Link'
import UiFlag from './UI/Flag'
import UiBadge from './UI/Badge'
import UiCtkIcon from './UI/Icon/CtkIcon'
import UiMaterialIcon from './UI/Icon/MaterialIcon'

import UiLoader from './UI/Loader'
import UiLoaderSpinner from './UI/Loader/Spinner'
import UiLoaderCeva from './UI/Loader/Ceva'

/**
 * Import globally some components that are used across the app.
 */

Vue.component('UiButton', UiButton)
Vue.component('UiLink', UiLink)
Vue.component('UiFlag', UiFlag)
Vue.component('UiBadge', UiBadge)
Vue.component('UiLoader', UiLoader)
Vue.component('UiCtkIcon', UiCtkIcon)
Vue.component('UiMaterialIcon', UiMaterialIcon)
Vue.component('UiLoaderSpinner', UiLoaderSpinner)
Vue.component('UiLoaderCeva', UiLoaderCeva)
Vue.component('UiLoaderCeva', UiLoaderCeva)
