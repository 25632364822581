<template>
  <div class="summary-offer tw-flex tw-flex-col tw-h-full">
    <div
      class="tw-mb-6"
      data-test="header"
    >
      <h1
        v-text="$t('offer.booking_dialog.summary_offer.title')"
        class="tw-text-xl tw-font-normal tw-mb-1"
        data-test="title"
      />
      <p
        v-text="content"
        class="tw-mb-0 tw-text-secondary-text"
        data-test="content"
      />
    </div>
    <div class="md:tw-overflow-y-auto md:tw-max-h-96 tw-flex-1">
      <!-- Accessibility -->
      <accessibility-item
        v-if="offer.pickup.address.accessibilities"
        :title="$t('offers.titles.accessibility.pickup')"
        :content="offer.pickup.address.accessibilities"
        class="tw-mb-4"
        data-test="pickup-accessibility"
      />

      <accessibility-item
        v-if="offer.delivery.address.accessibilities"
        :title="$t('offers.titles.accessibility.delivery')"
        :content="offer.delivery.address.accessibilities"
        class="tw-mb-4"
        data-test="delivery-accessibility"
      />

      <accessibility-item
        v-if="pickupAccessibility && !(offer.pickup.address.accessibility && offer.pickup.address.accessibility.vehicle_type.key !== 'standard_trailer')"
        :title="$t('offers.titles.accessibility.pickup')"
        :content="[pickupAccessibility.comment]"
        class="tw-mb-4"
        data-test="pickup-accessibility-line"
      />

      <accessibility-item
        v-if="deliveryAccessibility && !(offer.delivery.address.accessibility && offer.delivery.address.accessibility.vehicle_type.key !== 'standard_trailer')"
        :title="$t('offers.titles.accessibility.delivery')"
        :content="[deliveryAccessibility.comment]"
        class="tw-mb-4"
        data-test="delivery-accessibility-line"
      />

      <!-- Pickup/delivery comments -->
      <specificity-item
        v-if="offer.pickup.comment"
        :icon="'comment'"
        :title="$t('app.labels.comment')"
        :direction="'pickup'"
        :content="offer.pickup.comment"
        data-test="pickup-comment"
      />
      <specificity-item
        v-if="offer.delivery.comment"
        :icon="'comment'"
        :title="$t('app.labels.comment')"
        :direction="'delivery'"
        :content="offer.delivery.comment"
        data-test="delivery-comment"
      />

      <!-- Tail lift -->
      <specificity-item
        v-if="offer.pickup.handling.tail_lift || offer.delivery.handling.tail_lift"
        :icon="'hatch'"
        :title="$t('app.labels.vehicle_options')"
        :direction="offer.pickup.handling.tail_lift && offer.delivery.handling.tail_lift
          ? 'both'
          : offer.pickup.handling.tail_lift
            ? 'pickup' : 'delivery'"
        :content="$t('app.values.vehicle_tail_lift')"
        data-test="tail-lift"
      />

      <!-- Handling -->
      <specificity-item
        v-if="offer.pickup.handling.driver || offer.delivery.handling.driver"
        :icon="'logistic'"
        :title="$t('app.labels.handling')"
        :direction="offer.pickup.handling.driver && offer.delivery.handling.driver
          ? 'both'
          : offer.pickup.handling.driver
            ? 'pickup' : 'delivery'"
        :content="$t('handling_by_the_driver')"
        data-test="handling"
      />

      <!-- Load comment -->
      <specificity-item
        v-if="offer.load.description"
        :icon="'comment'"
        :title="$t('app.labels.comment')"
        :direction="'load'"
        :content="offer.load.description"
        data-test="load-comment"
      />

      <!-- ADR load -->
      <specificity-item
        v-if="adr"
        :icon="'skull'"
        :title="$t('app.labels.adr_load')"
        :direction="null"
        :content="adr.comment"
        data-test="adr"
      />

      <!-- Adjustment -->
      <specificity-item
        v-if="adjustment"
        :icon="'adjust'"
        :title="$t('app.labels.adjustment')"
        :direction="null"
        :content="adjustment.comment"
        data-test="adjustment"
      />

      <!-- Dates -->
      <specificity-item
        v-if="hasDates"
        :icon="'scheduled'"
        :title="$t('app.labels.date_hour')"
        :direction="hasPickupDates && hasDeliveryDates
          ? 'both'
          : hasPickupDates
            ? 'pickup'
            : 'delivery'"
        data-test="dates"
      >
        <template #content>
          <ul
            class="tw-flex tw-flex-col tw-list-none tw-m-0 tw-p-0"
          >
            <li
              v-if="hasPickupDates"
              v-text="pickupDate"
              data-test="pickup"
            />
            <li
              v-if="hasDeliveryDates"
              v-text="deliveryDate"
              data-test="delivery"
            />
          </ul>
        </template>
      </specificity-item>

      <!-- Pickup/delivery rendez-vous -->
      <specificity-item
        v-if="!!pickupRdv"
        :icon="'scheduled'"
        :title="$t('app.labels.rdv_option.pickup')"
        :direction="'pickup'"
        :content="pickupRdv"
        data-test="rdv-pickup"
      />
      <specificity-item
        v-if="!!deliveryRdv"
        :icon="'scheduled'"
        :title="$t('app.labels.rdv_option.delivery')"
        :direction="'delivery'"
        :content="deliveryRdv"
        data-test="rdv-delivery"
      />

      <!-- Pickup/delivery additional step -->
      <specificity-item
        v-if="pickupAdditionalStep"
        :icon="'destination'"
        :title="$t('app.labels.additional_step.pickup')"
        :direction="'pickup'"
        :content="pickupAdditionalStep.comment"
        data-test="additional-step-pickup"
      />
      <specificity-item
        v-if="deliveryAdditionalStep"
        :icon="'destination'"
        :title="$t('app.labels.additional_step.delivery')"
        :direction="'delivery'"
        :content="deliveryAdditionalStep.comment"
        data-test="additional-step-delivery"
      />
    </div>
    <div
      class="summary-offer__footer tw-relative tw-w-full tw-flex tw-flex-col tw-items-center tw-py-4 md:tw-pb-0 tw-mt-4 md:tw-mt-0"
      data-test="footer"
    >
      <b-form-checkbox
        v-model="formData.tos"
        name="terms-of-service"
        class="tw-mb-6"
        required
        data-test="tos"
      >
        {{ $t('app.paragraphs.tos') }}
      </b-form-checkbox>

      <div
        :class="{
          'tw-justify-center': !hasBackButton,
          'tw-justify-between': hasBackButton
        }"
        class="tw-flex tw-flex-col-reverse md:tw-flex-row tw-items-center tw-w-full"
      >
        <ui-button
          v-if="hasBackButton"
          class="tw-w-full md:tw-w-auto tw-mt-4 md:tw-mt-0 tw-w-full md:tw-w-auto"
          variant="link"
          data-test="back-button"
          @click="$emit('back')"
        >
          {{ $t('back') | capitalize }}
        </ui-button>

        <ui-button
          :disabled="!formData.tos"
          class="tw-px-4 tw-w-full md:tw-w-auto"
          variant="primary"
          data-test="next-button"
          @click="$emit('validate')"
        >
          <slot name="text-button" />
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, defineComponent, reactive, toRefs } from '@vue/composition-api'
  import moment from 'moment'

  import SpecificityItem from './_subs/SpecificityItem/index.vue'
  import AccessibilityItem from './_subs/AccessibilityItem/index.vue'

  import useI18n from '@/composables/useI18n'
  import { useMeetingComment } from '@/composables/carriers/offers/useMeeting'

  export default defineComponent({
    name: 'SummaryOffer',
    components: {
      SpecificityItem,
      AccessibilityItem
    },
    props: {
      offer: {
        type: Object,
        required: true
      },
      button: {
        type: String,
        default: null
      },
      content: {
        type: String,
        default: null
      },
      hasBackButton: {
        type: Boolean,
        default: false
      }
    },
    emits: [
      'back',
      'next'
    ],
    setup (props) {
      const { offer } = toRefs(props)

      const i18n = useI18n()

      const formData = reactive({
        tos: false
      })

      /**
       * @function getPriceLine
       * @param {string} lineKey
       * @returns {{
       *   key: string
       * } | undefined}
       */
      function getPriceLine (lineKey) {
        if (!offer.value.pricing || !offer.value.pricing.price_lines) return

        // @ts-ignore
        return offer.value.pricing?.price_lines?.find(line => line.key === lineKey)
      }

      const adr = computed(() => getPriceLine('adr_dangerous_goods_by_road'))
      const adjustment = computed(() => getPriceLine('shipper_load_incorrect_configuration'))
      const pickupRdv = computed(() => useMeetingComment(offer.value.pickup))
      const deliveryRdv = computed(() => useMeetingComment(offer.value.delivery))
      const pickupAdditionalStep = computed(() => getPriceLine('pickup_additional_step'))
      const deliveryAdditionalStep = computed(() => getPriceLine('delivery_additional_step'))
      const pickupAccessibility = computed(() => getPriceLine('pickup_address_accessibility'))
      const deliveryAccessibility = computed(() => getPriceLine('delivery_address_accessibility'))

      const hasPickupDates = computed(() => {
        const { pickup } = offer.value
        return !pickup.time_slot.flexible || !!getPriceLine('mission_window_short_late_2h_pickup') || !!getPriceLine('mission_window_very_short_late_1h_pickup')
      })

      const hasDeliveryDates = computed(() => {
        const { delivery } = offer.value
        return !delivery.time_slot.flexible || !!getPriceLine('mission_window_short_late_2h_delivery') || !!getPriceLine('mission_window_very_short_late_1h_delivery')
      })

      const hasDates = computed(() => hasDeliveryDates.value || hasPickupDates.value)

      const pickupDate = computed(() => {
        const { date, flexible, start_time: startTime, end_time: endTime } = offer.value.pickup.time_slot

        return i18n.value.t(flexible
          ? 'app.values.pickup_flexible'
          : 'app.values.pickup_not_flexible', {
          date: moment(date).format('LL'),
          startTime: moment(`${date} ${startTime}`).format('LT'),
          endTime: moment(`${date} ${endTime}`).format('LT')
        })
      })

      const deliveryDate = computed(() => {
        const { date, flexible, start_time: startTime, end_time: endTime } = offer.value.delivery.time_slot

        return i18n.value.t(flexible
          ? 'app.values.delivery_flexible'
          : 'app.values.delivery_not_flexible', {
          date: moment(date).format('LL'),
          startTime: moment(`${date} ${startTime}`).format('LT'),
          endTime: moment(`${date} ${endTime}`).format('LT')
        })
      })

      return {
        pickupRdv,
        deliveryRdv,
        formData,
        adr,
        adjustment,
        pickupAdditionalStep,
        deliveryAdditionalStep,
        pickupAccessibility,
        deliveryAccessibility,
        hasDates,
        hasPickupDates,
        hasDeliveryDates,
        pickupDate,
        deliveryDate
      }
    }
  })
</script>

<style lang="scss">
.summary-offer .specificity-item {
  margin-bottom: 1rem;
}
</style>
