// @ts-nocheck
export default {
  SET_MISSIONS (state, { items }) {
    state.missions.items = items
  },
  PUSH_MISSIONS (state, { items }) {
    items.forEach((value) => {
      const foundIndex = state.missions.items.findIndex(e => e.uuid === value.uuid)
      if (foundIndex !== -1) {
        state.missions.items[foundIndex] = value
      } else {
        state.missions.items.push(value)
      }
    })
  },
  SET_CURRENT_MISSION (state, mission) {
    /**
     * TODO: This current mission should've been just a uuid instead of
     * the whole mission object.
     */
    if (mission.uuid !== state.missionUuidToShow) {
      state.missionUuidToShow = null
    }
    state.currentMission = mission
  },
  RESET_CURRENT_MISSION (state) {
    state.currentMission = {}
    state.missionUuidToShow = null
  },
  SET_METRICS (state, { type, counts }) {
    state.metrics[type] = counts
  },
  SET_CURRENT_MISSION_STATUS (state, val) {
    state.currentMissionStatus = val
  },
  UPDATE_PROOF_OF_DELIVERY (state, { value }) {
    const { uuid, status } = value
    const objIndex = state.missions.items.findIndex((obj) => obj.uuid === uuid)
    if (objIndex !== -1) {
      const mission = state.missions.items[objIndex]
      mission.proof_of_delivery.state = status
      if (status === 'available') mission.proof_of_delivery.files = [true]
      /**
       * Apply the same change for the current mission if the ids match
       */
      if (state.currentMission && state.currentMission.uuid === mission.uuid) {
        state.currentMission.proof_of_delivery.state = status
        if (status === 'available') state.currentMission.proof_of_delivery.files = [true]
      }

      /**
       * Ensure the mission steps are done once the proof of delivery was uploaded.
       * Case where the pickup nor the delivery is still in the "pending" state
       */
      mission.pickup.state = 'completed'
      mission.delivery.state = 'completed'
    }
  },
  UPDATE_MISSION (state, { value }) {
    const { uuid, data } = value
    const objIndex = state.missions.items.findIndex((obj) => obj.uuid === uuid)

    if (objIndex !== -1) {
      state.missions.items[objIndex] = Object.assign(state.missions.items[objIndex], data)
    }
  },
  SET_MISSION_UUID_TO_SHOW (state, value) {
    state.missionUuidToShow = value
  },
  PUSH_MISSION_ITINERARY (state, { uuid, itinerary }) {
    const found = state.itineraries.findIndex(e => e.uuid === uuid)
    if (found === -1) {
      state.itineraries.push({
        uuid,
        itinerary
      })
    }
  },
  SET_MISSIONS_META (state, { meta }) {
    state.missions.meta = meta
  },
  SET_MISSIONS_METRICS (state, { type, status, meta }) {
    if (state.metrics[type][status]) state.metrics[type][status] = meta.item_count
  },
  UPDATE_CURRENT_MISSION_OWNER (state, { owner }) {
    state.currentMission.owner = owner
  },
  SET_FETCH_MISSIONS_ACTIVE (state, val) {
    state.fetchMissionsActive = val
  },
  TOGGLE_MISSIONS_FILTER (state, { filter }) {
    state.missions.filters[filter] = !state.missions.filters[filter]
  },
  SET_MISSIONS_FILTER (state, { filter, value }) {
    state.missions.filters[filter] = value
  },
  SET_MISSION_BILLING (state, data) {
    state.billing = data
  }
}
