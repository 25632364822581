// @ts-nocheck
import AxiosResource from './AxiosResource'

export default class RegistrationResource extends AxiosResource {
  constructor () {
    super()

    this.path = '/registration'
  }

  fetchUserInvite (...args) {
    return this.action('GET', '/user-invites/{uid}', ...args)
  }

  userInvite (...args) {
    return this.action('POST', '/user-invites/{uid}', ...args)
  }
}
