// @ts-nocheck
import AxiosResource from './AxiosResource'

export default class ShipmentResource extends AxiosResource {
  constructor () {
    super()

    this.path = '/v2/companies/{cid}/shipments{/sid}'
  }

  metrics (...args) {
    const metrics = new AxiosResource()
    metrics.path = '/v2/companies/{cid}/metrics/shipments'
    return metrics.get(...args)
  }

  phone (...args) {
    return this.action('PUT', '/shipper-phone-number', ...args)
  }

  travel (...args) {
    return this.action('GET', '/travel', ...args)
  }

  billing (...args) {
    return this.action('GET', '/billing', ...args)
  }
}
