// @ts-nocheck
export default {
  getShipmentsMetrics: ({ shipments }) => shipments.metrics,
  getDashboardMetrics: ({ dashboard }) => dashboard.metrics,
  getEstimation: ({ estimation }) => estimation,
  getExpressQuoteAddress: ({ expressQuote }) => expressQuote.address,
  getExpressQuoteLoad: ({ expressQuote }) => expressQuote.load,
  hasUnpaidShipments (state, getters) {
    if (!getters.getShipmentsMetrics) return false
    const { counts } = getters.getShipmentsMetrics
    if (!counts) return false

    return counts.requires_payment.total > 0
  }
}
