export default class SearchModel {
  constructor () {
    return {
      pickup_iso_codes: [],
      delivery_iso_codes: [],
      max_length: maxLengthInCm,
      max_height: maxHeightInCm,
      max_weight: maxWeightInKg,
      max_width: maxWidthInCm,
      without_tail_lift: false,
      send_by_email: false,
      name: '',
      description: '',
      count: null
    }
  }
}

export const maxLengthInCm = 1320
export const maxWidthInCm = 240
export const maxHeightInCm = 270
export const maxWeightInKg = 28000
