<template>
  <div class="ctk-nav-bar-actions tw-flex tw-flex-col md:tw-flex-row">
    <router-link
      :to="{
        name: 'Faq'
      }"
      data-test="faq-link"
      class="ctk-nav-bar-actions__button tw-mb-2 md:tw-mb-0 hover:tw-no-underline"
      @click.native="$emit('close')"
    >
      <ui-ctk-icon
        name="help"
        data-test="icon"
      />
      <span
        v-text="$options.filters.capitalize($t('navigation.faq'))"
        class="tw-hidden lg:tw-block"
        data-test="content"
      />
    </router-link>

    <template>
      <button
        ref="search-button"
        :title="$t('app.buttons.search')"
        class="ctk-nav-bar-actions__button tw-mb-2 md:tw-mb-0"
        type="button"
        data-test="search"
        v-matomo="{
          click: {
            category: 'Shipments',
            action: 'Initiated Search'
          }
        }"
        @click="search"
      >
        <ui-ctk-icon
          name="search"
        />
        <span
          class="tw-hidden"
          v-text="$t('app.buttons.search')"
        />
      </button>
    </template>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import { EventBus } from '@/services/EventBus'

  /**
   * @module component - CtkNavBarActions
   * @emits close
   */
  export default defineComponent({
    name: 'CtkNavBarActions',
    computed: {
      ...mapGetters([
        'isUserShipper'
      ])
    },
    methods: {
      /**
       * Show the search dialog
       * @function search
       */
      search () {
        this.$emit('close')
        EventBus.$emit('dialogs:search', this.$refs['search-button'])
      }
    }
  })
</script>

<style lang="scss" scoped>
.ctk-nav-bar-actions__button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 55px;
  color: $info-light;
  background-color: transparent;
  transition: background-color 200ms, border-bottom 200ms;
}
.ctk-nav-bar-actions__button .ctk-font {
  font-size: 30px;
}
.ctk-nav-bar-actions__button:hover, .ctk-nav-bar-actions__button:focus {
  background-color: $primary-lighten;
  color: white;
  outline: none;
}
.ctk-nav-bar-actions__button.router-link-active {
  background-color: $primary;
  color: white;
  outline: none;
}
@media (min-width: 770px) {
  .ctk-nav-bar-actions__button.router-link-active {
    border-bottom: 3px solid $brand-accent;
  }
}
.ctk-nav-bar-actions--mobile {
  padding: 0 10px;
}
.ctk-nav-bar-actions--mobile .ctk-nav-bar-actions__button {
  width: 100%;
  text-align: left;
  max-height: 30px;
  padding-left: 0;
}
.ctk-nav-bar-actions--mobile .ctk-nav-bar-actions__button span {
  display: inline-block;
}
.ctk-nav-bar-actions--mobile .ctk-nav-bar-actions__button.router-link-active {
  background-color: $primary-lighten;
}
</style>
