<template>
  <div
    :id="`popover-triangle-${$attrs.id}`"
    :class="{ small }"
    class="triangle-popover tw-absolute tw-bottom-0 tw-left-0 tw-overflow-hidden"
  >
    <ui-ctk-icon
      name="bid-2"
      :class="`triangle-text-${ status }`"
      data-test="icon"
    />

    <b-popover
      ref="popover"
      :target="`popover-triangle-${$attrs.id}`"
      :custom-class="'bg-secondary'"
      triggers="focus hover"
      class="triangle-popover"
    >
      <slot />
    </b-popover>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - TrianglePopover
   * @param {boolean} [small=false]
   */
  export default defineComponent({
    inheritAttrs: false,
    name: 'TrianglePopover',
    props: {
      small: {
        type: Boolean,
        default: false
      },
      status: {
        type: String,
        required: true
      }
    }
  })
</script>

<style lang="scss" scoped>

  .triangle-popover {
    $triangle-size: 40px;

    border-bottom-left-radius: 4px;
    height: $triangle-size;
    width: $triangle-size;

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      bottom: 0;
      left: 0;
      border-style: solid;
      border-width: $triangle-size 0 0 $triangle-size;
      border-color: transparent;
      z-index: 0;
    }

    .ctk-font {
      font-size: 30px;
      position: absolute;
      bottom: 0;
      left: -3px;
      z-index: 1;
    }

    &.small {
      border-bottom-left-radius: 0;

      &::after {
        border-width: 35px 0 0 35px;
      }

      .ctk-font {
        font-size: 26px;
      }
    }
  }

  .triangle-text-pending {
    color: $text-pending;
  }

  .triangle-text-accepted {
    color: $text-accepted;
  }

  .triangle-text-danger {
    color: $text-danger
  }

</style>
