// @ts-nocheck
export default {
  SET_ADDRESS (state, { direction, address }) {
    state[direction].address = address
  },
  SET_COMMENT (state, { direction, comment }) {
    state[direction].comment = comment
  },
  SET_MEETING (state, { direction, meeting }) {
    state[direction].meeting = meeting
  },
  SET_CONTACT (state, { direction, contact }) {
    state[direction].contact = contact
  },
  SET_TIME_SLOT (state, { direction, startTime, endTime }) {
    state[direction].timeslot.start_time = startTime
    state[direction].timeslot.end_time = endTime
  },
  SET_FETCHED_SHIPMENTS (state, shipments) {
    state.fetchedShipments = shipments
  },
  SET_GUARD (state, { guard, value }) {
    state.guards[guard] = value
  },
  SET_PALLETS (state, pallets) {
    state.pallets = pallets
  },
  SET_GOODS (
    state,
    { type, height, weight, width, length, quantity, format, description, pallets, insuranceType, insuredLoadValue }
  ) {
    state.load.type = type
    state.load.height = height
    state.load.weight = weight
    state.load.width = width
    state.load.length = length
    state.load.quantity = quantity
    state.load.format = format
    state.load.description = description
    state.load.insuranceType = insuranceType
    state.load.insuredLoadValue = insuredLoadValue

    /**
     * Handle case where there is no pallets list available. (eg. from the price quotation form)
     */
    if (!pallets) {
      state.load.pallets = [
        {
          quantity,
          format,
          length,
          width
        }
      ]
    } else {
      state.load.pallets = pallets
    }
  },
  SET_HANDLING_DRIVER (state, { direction, value }) {
    state[direction].handling.driver = value
  },
  SET_HANDLING_TAIL_LIFT (state, { direction, value }) {
    state[direction].handling.tail_lift = value
  },
  SET_PHONE_NUMBER (state, phoneNumber) {
    state.phoneNumber = phoneNumber
  },
  SET_EXPIRATION_DATE (state, date) {
    state.expirationDate = date
  },
  SET_SHIPPER_REFERENCE (state, reference) {
    state.shipperReference = reference
  },
  SET_CONFIRMATION_STEP (state, confirmation) {
    state.isConfirmation = confirmation
  }
}
