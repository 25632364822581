// @ts-nocheck
import Vue from 'vue'
import { Offer, Search, Proposal } from '@/resources'
import { EventBus } from '@/services/EventBus'
import SearchModel, { maxLengthInCm } from './models/SearchModel'

const buildBodyForSearching = (search) => {
  const {
    max_length: maxLength,
    max_weight: maxWeight,
    max_height: maxHeight,
    max_width: maxWidth,
    min_length: minLength
  } = search

  const searchParams = {}
  /**
   * Send the min_length only if it's not less or equal than zero
   */
  if (minLength) searchParams.min_length = minLength

  /**
   * TODO: Refactor this mess
   */
  if (maxLength || maxLength === 0) searchParams.max_length = maxLength === 0 ? 1 : maxLength
  if (maxHeight || maxHeight === 0) searchParams.max_height = maxHeight === 0 ? 1 : maxHeight
  if (maxWidth || maxWidth === 0) searchParams.max_width = maxWidth === 0 ? 1 : maxWidth
  if (maxWeight || maxWeight === 0) searchParams.max_weight = maxWeight === 0 ? 1 : maxWeight
  searchParams.without_tail_lift = search.without_tail_lift ? 1 : 0

  const { pickup_iso_codes: pickupIsoCode, delivery_iso_codes: deliveryIsoCode } = search

  if (pickupIsoCode && pickupIsoCode.length > 0) {
    searchParams.pickup_iso_codes = pickupIsoCode.join(',')
  }
  if (deliveryIsoCode && deliveryIsoCode.length > 0) {
    searchParams.delivery_iso_codes = deliveryIsoCode.join(',')
  }

  if (search.max_length === maxLengthInCm) {
    // do not send the max_length query parameter if we are at the maximum
    delete searchParams.max_length
  }

  return searchParams
}

const itemPerPage = 30

export default {
  /**
   * @function getOffers
   */
  getOffers ({ commit, state, getters, dispatch, rootGetters }, ctx) {
    return new Promise((resolve, reject) => {
      commit('SET_OFFER_PAGINATION', 1)
      let params = {
        limit: itemPerPage,
        page: (ctx && ctx.pageNumber) || 1,
        sort: getters.getOffersSortType
      }

      params = Object.assign(params, buildBodyForSearching(state.currentSearch))

      Offer.get({
        cid: rootGetters['auth/getCid']
      }, { params })
        .then(response => {
          const { items, meta } = response.data

          if (!items.length) {
            dispatch('resetCurrentOffer')
          }

          /**
           * Fetch offer metrics along
           */
          dispatch('retrieveOffersMetrics')

          commit('SET_CURRENT_OFFERS_META', meta)
          commit('SET_OFFERS', items)

          /**
           * If the getOffers function was called after a click in one of the
           * offers filters (countries, load, ...), trigger a Matomo event
           * with the offers count.
           */
          if (ctx && ctx.fromFilter && Vue.prototype.$matomo) {
            Vue.prototype.$matomo.trackEvent('Freight Searches', 'Filter', 'result', meta.item_count)
          }

          if (meta.pagination.current_page > 1 && meta.pagination.current_page > state.currentOffersMeta.pagination.page_count) {
            dispatch('getOffers', (state.currentOffersMeta.pagination.page_count, buildBodyForSearching(state.currentSearch)))
          }
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  retrieveOffers ({ dispatch, rootState }, fromFilter) {
    if (rootState.route.name === 'Offers' || rootState.route.name === 'Searches') EventBus.$emit('get-offers', fromFilter)

    /**
     * Since the user updated his offers parameters, remove the offers suggestion.
     */
    dispatch('setOffersSuggestionsVisibility', false)
  },
  loadMoreOffers ({ commit, state, getters, rootGetters }) {
    let params = { limit: itemPerPage, page: state.offerPagination, sort: getters.getOffersSortType }
    const searchParams = buildBodyForSearching(state.currentSearch)
    if (searchParams) {
      params = Object.assign(params, searchParams)
    }

    return Offer.get({
      cid: rootGetters['auth/getCid']
    }, { params })
      .then(response => {
        commit('SET_CURRENT_OFFERS_META', response.data.meta)
        const newOffers = []
        response.data.items.forEach((offer) => {
          newOffers.push(offer)
        })

        const offers = [...state.offers]
        newOffers.forEach((newOffer) => {
          const offerIndex = offers.findIndex(offer => offer.uuid === newOffer.uuid)
          if (offerIndex !== -1) {
            offers[offerIndex] = newOffer
          } else {
            offers.push(newOffer)
          }
        })

        commit('SET_OFFERS', offers)
        return response
      })
      .catch(err => {
        if (!err.response) return

        return err
      })
  },
  deleteSearch ({ commit, state, dispatch, rootGetters }, search) {
    return Search.delete({
      cid: rootGetters['auth/getCid'],
      sid: search.uuid
    })
      .then(response => {
        commit('DELETE_SEARCH_IN_SEARCHES_WITH_ID', search.uuid)
        if (!state.searches.length) {
          dispatch('resetCurrentSearch')
          dispatch('getOffers')
        }

        return response
      })
  },
  toggleSearchAlert ({ commit, state, rootGetters }, search) {
    return new Promise((resolve, reject) => {
      const currentSearch = search || state.currentSearch
      commit('SET_SEARCH_ATTRIBUT', { search: currentSearch, key: 'send_by_email', val: !currentSearch.send_by_email })
      Search.update({ cid: rootGetters['auth/getCid'], sid: currentSearch.uuid }, JSON.stringify(currentSearch)).then(response => {
        commit('UPDATE_A_SEARCH', currentSearch)
        resolve(response)
      }, (error) => {
        commit('SET_SEARCH_ATTRIBUT', { search: currentSearch, key: 'send_by_email', val: !currentSearch.send_by_email })
        reject(error)
      })
    })
  },
  hideOffer ({ commit, state, dispatch, rootGetters }) {
    return Offer.delete({ cid: rootGetters['auth/getCid'], oid: state.currentOffer })
      .then(() => {
        setTimeout(() => {
          commit('DELETE_OFFER_IN_OFFERS_WITH_ID', state.currentOffer)
          dispatch('resetCurrentOffer')
          dispatch('refreshSearchesCount')
        }, 1000)
      }, (error) => {
        console.log('error hide offer', error)
      })
  },
  cancelProposal ({ rootGetters }, proposal) {
    return Proposal.cancel({
      cid: rootGetters['auth/getCid'],
      pid: proposal.uuid
    })
  },
  getSearch ({ commit, rootGetters }) {
    return Search.get({
      cid: rootGetters['auth/getCid']
    }, {
      params: {
        limit: 30
      }
    })
      .then(response => {
        if (response.status === 200) {
          commit('SET_SEARCHES', response.data.items
            .map(search => Object.assign(new SearchModel(), search)))
        }

        return response
      })
  },
  saveSearch ({ commit, rootGetters }, search) {
    return new Promise((resolve, reject) => {
      const searchBody = { ...search }

      if (typeof searchBody.min_length === 'number') {
        if (searchBody.min_length === 0) {
          // there is no need to store min length as if it searches for all length below max length
          delete searchBody.min_length
        } else {
          searchBody.min_length = parseInt(searchBody.min_length, 10)
        }
      }

      if (typeof searchBody.max_length === 'number') {
        if (searchBody.max_length === maxLengthInCm) {
          // there is no need to store max length as if it searches for all length above min length
          delete searchBody.max_length
        } else {
          searchBody.max_length = parseInt(searchBody.max_length, 10)
        }
      }

      searchBody.max_height = parseInt(search.max_height, 10)
      searchBody.max_weight = parseInt(search.max_weight, 10)
      searchBody.max_width = parseInt(search.max_width, 10)

      Search.save({ cid: rootGetters['auth/getCid'] }, searchBody).then(response => {
        searchBody.uuid = response.data.uuid
        commit('ADD_SEARCH_IN_SEARCHES', Object.assign(new SearchModel(), searchBody))
        resolve(response)
      }, error => {
        console.log('error save search', error)
        reject(error)
      })
    })
  },
  retrieveMoreProposals ({ dispatch, getters, rootGetters }, { state: proposalState }) {
    return new Promise((resolve, reject) => {
      const meta = getters.getProposalsMeta
      const canLoadMore = meta.pagination.current_page + 1 <= meta.pagination.page_count

      if (canLoadMore) {
        /**
         * Build the query
         */
        const queryParams = {
          limit: itemPerPage,
          page: meta.pagination.current_page + 1
        }

        if (proposalState !== 'all') queryParams.state = proposalState

        Proposal.get({
          cid: rootGetters['auth/getCid']
        }, {
          params: queryParams
        })
          .then((response) => {
            dispatch('pushProposals', response.data.items)
            dispatch('setProposalsMeta', response.data.meta)

            resolve(response)
          })
          .catch((err) => {
            console.error('Error ocurred', err)
            reject(err)
          })
      } else {
        resolve()
      }
    })
  },
  retrieveOffersMetrics ({ commit, rootGetters }) {
    return Offer.get({
      cid: rootGetters['auth/getCid']
    }, {
      params: {
        limit: 0
      }
    })
      .then(response => {
        if (response.status === 200) {
          const {
            item_count: itemCount,
            unviewed_item_count: unviewedItemCount
          } = response.data.meta

          commit('SET_OFFERS_METRICS', {
            total: itemCount,
            unviewed_item_count: unviewedItemCount
          })
        }
      })
      .catch(() => {})
  },
  retrieveProposalsMetrics ({ commit, rootGetters }) {
    const total = Proposal.get({
      cid: rootGetters['auth/getCid']
    }, {
      params: {
        limit: 0
      }
    })
      .then(response => {
        if (response.status === 200) {
          /**
           * Adding this Error exception to debug when the response.data is null.
           * See Sentry #APP-5C
           */
          if (!response.data || !response.data.meta) throw new Error(`Missing required data in request response: ${response.toString()}`)

          commit('SET_PROPOSALS_METRICS', {
            total: response.data.meta.item_count
          })
        }
      })
      .catch(() => {})

    const pending = Proposal.get({
      cid: rootGetters['auth/getCid']
    }, {
      params: {
        limit: 0,
        state: 'pending'
      }
    })
      .then(response => {
        if (response.status === 200) {
          commit('SET_PROPOSALS_METRICS', {
            pending: response.data.meta.item_count
          })
        }
      })
      .catch(() => {})

    return Promise.all([
      total,
      pending
    ])
  },
  retrieveProposalsByStatus ({ dispatch, commit, getters, rootGetters }, { limit, state }) {
    const params = {
      limit: (limit === 0 ? limit : 30)
    }

    if (!state) {
      /**
       * The state was not specified in the request, use the saved one by default.
       */
      const filter = getters.getProposalsFilter
      if (filter !== 'all') {
        params.state = filter
      }
    } else {
      params.state = state
    }

    return Proposal.get({
      cid: rootGetters['auth/getCid']
    }, {
      params
    })
      .then(response => {
        if (limit !== 0 && response.data.items) {
          dispatch('setProposalsMeta', response.data.meta)
          commit('SET_PROPOSALS', response.data.items)
          dispatch('retrieveMoreProposals', {
            state: params.state
          })
        }
      })
  },
  setCurrentSearch ({ commit }, { search }) {
    commit('SET_CURRENT_SEARCH', search)
  },
  /**
   * This action is added to simplify the migration away from the EventBus nightmare.
   * Its purpose is to refresh the count of all the current searches.
   * This could be made more efficiently.
   */
  refreshSearchesCount ({ getters, dispatch }) {
    const searches = getters.getSavedSearches
    const promises = searches.map(search => {
      dispatch('refreshSearchCount', search)
    })

    return Promise.all(promises)
  },
  refreshSearchCount ({ getters, rootGetters, commit }, search) {
    const params = Object.assign({ limit: 0 }, buildBodyForSearching(search))

    return new Promise(resolve => {
      Offer.get({
        cid: rootGetters['auth/getCid']
      }, {
        params
      })
        .then(response => {
          const index = getters.getSavedSearches.findIndex(s => s.uuid === search.uuid)
          if (index !== -1) {
            const {
              item_count: itemCount,
              unviewed_item_count: unviewedItemCount
            } = response.data.meta

            commit('SET_COUNT_FOR_A_SEARCHES', {
              index,
              count: itemCount,
              unviewed: unviewedItemCount
            })
          }

          resolve(response)
        })
        .catch(() => { /* noop */ })
    })
  },
  /**
   * @function resetCurrentSearch
   */
  resetCurrentSearch ({ commit }) {
    commit('RESET_CURRENT_SEARCH')
  },
  resetCurrentOffer ({ commit }) {
    commit('RESET_CURRENT_OFFER')
  },
  setCurrentProposal ({ commit }, proposalUuid) {
    commit('SET_CURRENT_PROPOSAL', proposalUuid)
  },
  resetCurrentProposal ({ commit }) {
    commit('RESET_CURRENT_PROPOSAL')
  },
  setOffersSortType ({ commit }, sortType) {
    commit('SET_OFFERS_SORT_TYPE', {
      value: sortType
    })
  },
  setCurrentSearchIsoCodes ({ commit }, { direction, isoCodes }) {
    commit('SET_CURRENT_SEARCH_ISO_CODES', {
      direction,
      isoCodes
    })
  },
  /**
   * Set the current offer to the next item, according to the sorting filters
   * @function setNextOffer
   * @returns {object} offer - Returns the new offer
   */
  setNextOffer ({ dispatch, getters }) {
    const offers = getters.getSortedOffersList
    const currentOffer = getters.getCurrentOffer
    if (currentOffer && currentOffer.uuid) {
      const currentOfferIndex = offers.findIndex(e => currentOffer.uuid === e.uuid)

      if (currentOfferIndex !== -1) {
        // Got an offer for that uuid, seek for the next id
        const offer = offers[currentOfferIndex + 1]
        if (offer) {
          dispatch('setCurrentOffer', offer)
          dispatch('trackOffer', offer.uuid)
          return offer
        }
      }
    }
  },
  /**
   * Set the current offer to the previous item, according to the sorting filters
   * @function setPreviousOffer
   * @returns {object} offer - Returns the new offer
   */
  setPreviousOffer ({ dispatch, getters }) {
    const offers = getters.getSortedOffersList
    const currentOffer = getters.getCurrentOffer
    if (currentOffer) {
      const currentOfferIndex = offers.findIndex(e => currentOffer.uuid === e.uuid)

      if (currentOfferIndex !== -1) {
        // Got an offer for that uuid, seek for the previous id
        const offer = offers[currentOfferIndex - 1]
        if (offer) {
          dispatch('setCurrentOffer', offer)
          dispatch('trackOffer', offer.uuid)
          return offer
        }
      }
    }
  },
  /**
   * @function setKeyboardStatus
   * @param {boolean} status - Set if the keyboard is active or not
   */
  setKeyboardStatus ({ commit }, value) {
    commit('SET_KEYBOARD_STATUS', {
      value
    })
  },
  /**
   * Set a specific offer as the currently viewed one.
   * @function setCurrentOffer
   * @param {object} offer - The offer to set as the current offer
   */
  setCurrentOffer ({ commit }, offer) {
    commit('SET_CURRENT_OFFER', offer)
  },
  /**
   * Update the offer informations without overriding it.
   * @function updateCurrentOffer
   * @param {object} offer - The offer to set as the current offer
   */
  updateCurrentOffer ({ commit }, offer) {
    commit('UPDATE_CURRENT_OFFER', offer)
  },
  /**
   * Push the itinerary in the store
   * @function pushItinerary
   */
  pushItinerary ({ commit }, offerItinerary) {
    commit('PUSH_ITINERARY', offerItinerary)
  },
  /**
   * Set a new list of offers suggestions
   * @function setOffersSuggestions
   * @param {array} offers
   */
  setOffersSuggestions ({ commit }, offers) {
    commit('SET_OFFERS_SUGGESTIONS', {
      offers
    })
  },
  /**
   * @function trackOffer
   * @param {string} uuid
   * @returns {Promise<any>}
   */
  trackOffer ({ dispatch, rootGetters }, uuid) {
    return Offer.tracking({
      cid: rootGetters['auth/getCid'],
      oid: uuid
    })
      .then(() => {
        if (rootGetters['auth/isImpersonating']) return

        /**
         * Whenever we do a tracking call, we consider the offer as viewed.
         * We can safely update it to the view.
         */
        dispatch('addKeyValueToAnOffer', {
          offerUuid: uuid,
          key: 'is_viewed',
          value: true
        })

        /**
         * Force refresh of all the search & offers metrics.
         * This is very network expensive because if the user has many searches
         * or he clicks on many offers often.
         * We don't have any other way to update the counters efficiently.
         */
        dispatch('refreshSearchesCount')
          .catch(() => { /* noop */ })

        dispatch('retrieveOffersMetrics')
          .catch(() => { /* noop */ })
      })
      .catch(() => { /* noop */ })
  },
  /**
   * @function retrieveOffer
   * @param {string} uuid
   * @returns {Promise<any>}
   */
  retrieveOffer ({ dispatch, rootGetters }, uuid) {
    return Offer.get({
      cid: rootGetters['auth/getCid'],
      oid: uuid
    })
      .then(response => {
        dispatch('setCurrentOffer', response.data)

        return response
      })
  },
  /**
   * @function retrieveOffersSuggestion
   * @param {object} offer
   */
  retrieveOffersSuggestion ({ dispatch, state, getters, rootGetters }, offer) {
    return new Promise((resolve, reject) => {
      /**
       * @function getQueryParameters
       * @param {object} query
       * @returns {object} parameters
       */
      function getQueryParameters (query) {
        const params = {
          limit: itemPerPage,
          page: 1,
          sort: getters.getOffersSortType
        }

        return Object.assign(params, buildBodyForSearching(state.currentSearch), query)
      }

      /**
       * Build the query parameters
       */
      let params
      let dir
      const { division_code: pickupDivisionCode } = offer.pickup.address
      const { division_code: deliveryDivisionCode } = offer.delivery.address

      /**
       * If the pickup and delivery are different, fetch the offers from the same direction.
       * If not, fetch from the same direction.
       */
      if (pickupDivisionCode !== deliveryDivisionCode) {
        params = getQueryParameters({
          pickup_iso_codes: pickupDivisionCode,
          delivery_iso_codes: deliveryDivisionCode
        })
        dir = 'opposite_direction'
      } else {
        params = getQueryParameters({
          pickup_iso_codes: deliveryDivisionCode,
          delivery_iso_codes: pickupDivisionCode
        })
        dir = 'same_direction'
      }

      Offer.get({
        cid: rootGetters['auth/getCid']
      }, { params })
        .then(response => {
          const list = response.data.items
            .filter(v => v.uuid !== offer.uuid)
            .map((v) => {
              v.suggest_direction = dir
              return v
            })

          /**
           * If the direction is the opposite direction, then fetch the same direction if
           * we do not have enough items.
           */
          if (list.length < 5 && dir === 'opposite_direction') {
            /**
             * If we don't have enough items, seek offers from the same direction
             * only if both pickup and delivery are different
             */
            const newParams = getQueryParameters({
              pickup_iso_codes: deliveryDivisionCode,
              delivery_iso_codes: pickupDivisionCode
            })

            Offer.get({
              cid: rootGetters['auth/getCid']
            }, {
              params: newParams
            })
              .then((res) => {
                const secondList = res.data.items
                  .filter((v) => v.uuid !== offer.uuid)
                  .map((v) => {
                    v.suggest_direction = 'same_direction'
                    return v
                  })

                dispatch('setOffersSuggestions', [
                  ...list,
                  ...secondList
                ])
                resolve(res)
              })
              .catch((err) => {
                if (!err.response) return resolve(response)

                console.error('Error while retrieving offers proposal', err)
                dispatch('setOffersSuggestions', list)
                resolve(response)
              })
          } else {
            dispatch('setOffersSuggestions', list)
            resolve(response)
          }
        })
        .catch((err) => {
          if (!err.response) return reject(err)

          console.error('Error while retrieving offers proposal', err)
          reject(err)
        })
    })
  },
  setOffersSuggestionsVisibility ({ commit }, visible) {
    commit('SET_OFFERS_SUGGESTION_VISIBILITY', {
      visible
    })
  },
  setSelectedDimension ({ commit }, value) {
    commit('SET_SELECTED_DIMENSION', {
      value
    })
  },
  setProposalKey ({ commit }, payload) {
    commit('SET_PROPOSAL_KEY', payload)
  },
  updateASearch ({ commit }, { search }) {
    commit('UPDATE_A_SEARCH', search)
  },
  pushProposals ({ commit }, proposals) {
    commit('PUSH_PROPOSALS', { proposals })
  },
  setProposalsMeta ({ commit }, meta) {
    commit('SET_PROPOSALS_META', { meta })
  },
  updateProposalInProposalsAndInCurrentOffer ({ commit }, payload) {
    commit('UPDATE_PROPOSAL_IN_PROPOSALS_AND_IN_CURRENT_OFFER', payload)
  },
  deleteProposalInProposalsWithId ({ commit }, payload) {
    commit('DELETE_PROPOSAL_IN_PROPOSALS_WITH_ID', payload)
  },
  addKeyValueToAnOffer ({ commit }, payload) {
    commit('ADD_KEY_VALUE_TO_AN_OFFER', payload)
  },
  updateProposalToCurrentOffer ({ commit }, payload) {
    commit('UPDATE_PROPOSAL_TO_CURRENT_OFFER', payload)
  },
  setProposalsFilter ({ commit }, { filter }) {
    commit('SET_PROPOSALS_FILTER', {
      filter
    })
  },
  setSameZones ({ commit }, v) {
    commit('SET_SAME_ZONES', v)
  },
  setOfferPreviousRoute ({ commit }, from) {
    commit('SET_OFFER_PREVIOUS_ROUTE', from)
  }
}
