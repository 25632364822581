<template>
  <div
    :class="{
      'white tw-bg-white tw-text-secondary-text': white,
      'tw-bg-blue-500 tw-text-white': !white
    }"
    class="price-chip tw-font-medium tw-px-2 tw-py-1 tw-text-center"
  >
    <slot />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * Show a price inside a info chip
   * @module component - PriceChip
   * @param {boolean} white - Switch the background / text color to the white version
   */
  export default defineComponent({
    name: 'PriceChip',
    props: {
      white: {
        type: Boolean,
        default: false
      }
    }
  })
</script>

<style lang="scss" scoped>

  .price-chip {
    min-height: 30px;
  }

</style>
