<template>
  <ctk-dialog
    id="proposalConfirmationDialog"
    v-model="modalState"
    modal-class="dialog-proposal-confirmation"
    max-width="875px"
    hide-footer
    hide-header
  >
    <ctk-dialog-close-button
      class="tw-absolute tw-top-0 tw-right-0"
      data-test="close"
      @click.native="modalState = false"
    />

    <div class="tw-flex tw-flex-col tw-mx-auto tw-text-center tw-px-4 md:tw-px-10 tw-pb-8 md:tw-pb-10 tw-pt-8 md:tw-pt-16">
      <img
        src="@/assets/img/icons/confirmation.svg"
        width="80"
        height="80"
        alt=""
        class="tw-mb-8 tw-mx-auto"
        data-test="icon"
      >

      <h1
        v-text="$t('offers.titles.proposal_confirmation')"
        class="dialog-proposal-confirmation__title fs-22 tw-font-medium tw-mb-4 md:tw-whitespace-nowrap"
        data-test="title"
      />

      <p
        v-text="$t('offers.paragraphs.proposal_confirmation')"
        class="tw-text-secondary-lighten md:tw-w-2/3 tw-mx-auto"
        data-test="content"
      />

      <ui-button
        :to="{
          name: 'Proposals'
        }"
        class="tw-text-white tw-px-4 tw-mx-auto tw-mt-6"
        variant="primary"
        data-test="view-proposals"
        @click.native="modalState = false"
      >
        {{ $t('offer.proposals.filters.all_pending') }}
      </ui-button>
    </div>

    <img
      src="@/assets/img/illustrations/shipment_confirmation.svg"
      alt=""
      class="dialog-proposal-confirmation__illustration tw-w-full tw-pointer-events-none tw-select-none tw--mt-20 tw-hidden md:tw-block md:tw-p-8 tw-h-64 tw-object-cover tw-object-bottom"
    >
  </ctk-dialog>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkDialog from '@/components/CtkDialog/index.vue'
  import CtkDialogCloseButton from '@/components/CtkDialog/_subs/CtkDialogCloseButton/index.vue'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  /**
   * @module component - DialogProposalConfirmation
   */
  export default defineComponent({
    name: 'DialogProposalConfirmation',
    components: {
      CtkDialog,
      CtkDialogCloseButton
    },
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    model: {
      prop: 'value',
      event: 'input'
    },
    setup (props) {
      const { state: modalState } = useModelGetterSetter(props, 'value')

      return {
        modalState
      }
    }
  })
</script>
