// @ts-nocheck

export default {
  SET_SHIPMENTS_ITEMS (state, { items }) {
    state.shipments.items = items
  },
  SET_SHIPMENTS_META (state, { meta }) {
    state.shipments.meta = meta
  },
  SET_SHIPMENTS_METRICS (state, { metrics }) {
    state.shipments.metrics = metrics
  },
  SET_DASHBOARD_METRICS (state, data) {
    state.dashboard.metrics = data
  },
  SET_ESTIMATION (state, estimation) {
    state.estimation = estimation
  },
  SET_EXPRESS_QUOTE_ADDRESS (state, { direction, address }) {
    state.expressQuote.address[direction] = address
  },
  SET_EXPRESS_QUOTE_LOAD (state, load) {
    state.expressQuote.load = load
  }
}
